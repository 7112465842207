import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import {
    Chip,
    InputAdornment,
    MenuItem,
    Switch,
    TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CachedIcon from "@mui/icons-material/Cached";
import Tooltip from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AgGridReact } from "ag-grid-react";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    memo,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Untag } from "../../../assets/images/untag.svg";
import {
    disableLoader,
    requestCompComplete,
    requestCompFail,
    requestCompStart,
    requestComplete,
    requestFail,
    setTriggerUnsavedEdit,
    toastError,
    toastSuccessMessage,
} from "../../../store/features/global/global";
import {
    bulkEditMaterial,
    downloadAuditLogData,
    editMaterial,
    editSpecialAttribute,
    generateCountryLevelPrice,
    getPriceFileMaterialsAndCountryLevelPrices,
    refreshPriceRule,
    resetMaterialDates,
    setEditedMaterial,
    setUserResizedColumns,
    untagMaterials,
    setInnerTableToggleObject,
    getTopFiveMaterials,
    updateSimilarPFSMaterials,
    getSimilarOrPfsAPI,
    setMaterials as setReduxMaterials,
} from "../../../store/features/workbench/workbench";
import { fallbackFormatter } from "../../../utils/helpers/formatter";
import { numberColFilterParams } from "../../../utils/helpers/utility_helpers";
import { onFilterChanged } from "../../../utils/reactTable/callbacks/callbacks";
import PriceListLevelImageCell from "../../../utils/reactTable/cellRenderers/PriceListLevelImageCell";
import { ImageTooltipRenderer } from "../../../utils/reactTable/tooltipRenderer/TooltipRenderer";
import ClearAllFilterButton from "../../common/clearAllFilterButton/ClearAllFilterButton";
import Select from "../../common/filters/Select/Select";
import TableRowCount from "../../common/tableRowCount/TableRowCount";
import useTableRowCount from "../../common/tableRowCount/useTableRowCount";
import LoadingOverlay from "../../ui/loader/Loader";
import { PRICING_TEAM, SUPER_USER } from "../exceptionConfig/ExceptionConfig";
import DiscountUploadModal from "./DiscountUploadModal";
import { AuditLogModal, StyledMenu } from "./EUCountryLevelPrices";
import {
    DiscountTableLinkComponent,
    hierarchyColDef,
    priceRuleLinkRenderer,
} from "./EUPricePlanningTableHelpers";
import MaterialAlertException from "./MaterialAlertException";
import TableSettings, {
    tableSettingColumnWidthModes,
    tableSettingTabIds,
} from "./TableSettings";
import {
    APPROVAL_STATUS,
    ApprovalStatusRenderer,
    alertAndExceptionOptions,
    auditLogDownloader,
    checkIfMaterialProcessedForCountryPrice,
    checkMaterialApprovalStatus,
    checkMaterialSelectionValidity,
    dateComparator,
    filterAndMapColumnChannelWise,
    getMaterialFetchData,
    isMaterialBeingSynced,
    validatePrice,
} from "./euHelper";

import styles from "./EUPricePlanning.module.scss";

const actionRequired = { 1: { text: "Re-apply Price Rule" } };
let gridRef = null;
export const tableAttributes = [
    { label: "Image", value: "productImage" },

    { label: "Similar Material(SM)", value: "similarMaterial" },
    { label: "SM Image", value: "smImage" },
    { label: "SM Season", value: "smSeason" },
    { label: "SM Market", value: "smMarket" },
    { label: "SM Channel", value: "smChannel" },

    { label: "PFS WAS Material", value: "pfsWasMaterial" },
    { label: "PFS Was Image", value: "pfsWasImage" },
    { label: "PFS WAS Season", value: "pfsWasSeason" },
    { label: "PFS WAS Market", value: "pfsWasMarket" },
    { label: "PFS WAS Indicated", value: "pfsWasIndicated" },
    // { label: "PFS WAS (EUR)", value: "pfsWasEUR" },

    { label: "Approval Status", value: "approvalStatus" },
    { label: "Season Code", value: "seasonCode" },
    { label: "Special Attribute", value: "specialAttribute" },
    { label: "Season Name", value: "seasonName" },
    { label: "Price Rule", value: "priceRule" },
    { label: "Discount Table", value: "discountTable" },
    { label: "Parent Material Number", value: "parentMaterial" },
    { label: "Parent Season Code", value: "parentSeason" },
    { label: "Price Establishment", value: "priceEstablishment" },
    { label: "Type Of PE", value: "typeOfPE" },
    { label: "Action Required", value: "is_price_rule_applied" },
    { label: "Planning Brand", value: "planningBrand" },
    { label: "Planning Sub-brand", value: "planningSubBrand" },
    { label: "Brand Group", value: "brandGrp" },
    { label: "Brand Group ID", value: "brandGrpId" },
    { label: "Merch Division", value: "merchDiv" },
    { label: "Merch Division ID", value: "merchDivId" },
    { label: "Merch Org", value: "merchOrg" },
    { label: "Merch Org Code", value: "merchOrgCode" },
    { label: "Gender", value: "gender" },
    { label: "Gender Code", value: "genderCode" },
    { label: "Brand", value: "brand" },
    { label: "Brand ID", value: "brandId" },
    { label: "Merch Segment", value: "merchSeg" },
    { label: "Merch Segment Code", value: "merchSegCode" },
    { label: "Merch Size Group", value: "merchSizeGrp" },
    { label: "Merch Size Group Code", value: "merchSizeGrpCode" },
    { label: "Merch Category", value: "merchCat" },
    { label: "Merch Category Code", value: "merchCatCode" },
    { label: "Product Category", value: "prodCat" },
    { label: "Product Category Code", value: "prodCatCode" },
    { label: "Product Class", value: "prodClass" },
    { label: "Product Class Code", value: "prodClassCode" },
    { label: "Product Subclass", value: "prodSubclass" },
    { label: "Product Subclass Code", value: "prodSubclassCode" },
    { label: "Plan Level 1", value: "planLvl1" },
    { label: "Plan Level 1 Code", value: "planLvl1Code" },
    { label: "Plan Level 2", value: "planLvl2" },
    { label: "Plan Level 2 Code", value: "planLvl2Code" },
    { label: "Plan Level 3", value: "planLvl3" },
    { label: "Plan Level 3 Code", value: "planLvl3Code" },
    { label: "Plan Level 4", value: "planLvl4" },
    { label: "Plan Level 4 Code", value: "planLvl4Code" },
    { label: "Plan Level 5", value: "planLvl5" },
    { label: "Plan Level 5 Code", value: "planLvl5Code" },
    { label: "Material Description", value: "matDesc" },
    { label: "Material Group", value: "matGrp" },
    { label: "Material Group Code", value: "matGrpCode" },
    { label: "Style Number", value: "styleNum" },
    { label: "Color Description", value: "color_description" },
    { label: "Color", value: "color" },
    { label: "Color Code", value: "colorCode" },
    { label: "NRF Color Code", value: "nrfColorCode" },
    { label: "Merch Concept", value: "merchConcept" },
    { label: "Merch Concept Code", value: "merchConceptCode" },
    { label: "Merch Fabric", value: "merchFabric" },
    { label: "Merch Fabric Code", value: "merchFabricCode" },
    { label: "Plan Size Group", value: "planSizeGrp" },
    { label: "Plan Size Group Code", value: "planSizeGrpCode" },
    { label: "Product Label", value: "prodlabel" },
    { label: "Product Label Code", value: "prodLabelCode" },
    { label: "Pattern", value: "pattern" },
    { label: "Collection", value: "collection" },
    { label: "Collection Code", value: "collectionCode" },
    { label: "Board Number", value: "boardNumber" },
    { label: "Short Board", value: "shortBoard" },
    { label: "SAP Status", value: "sapStatus" },
    { label: "UPC Status", value: "upc_status" },
    { label: "IP Price Export Status", value: "ip_price_export_status" },
    { label: "GFE Pre-pack", value: "gfePrePack" },
    { label: "Legacy Material Number", value: "legacyMatNum" },
    { label: "Vendor Material Number", value: "vendor_material_nbr" },
    { label: "Item Number", value: "item_number" },
    { label: "Model Name", value: "model_name" },
    { label: "Model Long Description", value: "model_long_desc" },
    { label: "Model Short Description", value: "model_desc" },
    { label: "First Available Date", value: "first_available_dt" },
    { label: "MSRP Price Effective Date", value: "rtlPriceEffectiveDate" },
    { label: "WHSL Price Effective Date", value: "whslPriceEffectiveDate" },
    { label: "GFE Material Status", value: "gfeMatStatus" },
    { label: "DTC ITP Flag", value: "dtcItpFlag" },
    { label: "WHSL ITP Flag", value: "whslItpFlag" },
    { label: "Regional Standard Cost", value: "regStdCost" },
    { label: "Regional TFG Manual (EUR)", value: "regTfgMan" },
    { label: "Cost Origin", value: "costOrigin" },
    { label: "Cost Used", value: "costUsed" },
    { label: "Factory Cost Manual (USD)", value: "factoryCostMan" },
    { label: "US MSRP", value: "usMsrp" },
    { label: "US WHSL", value: "usWhsl" },
    {
        label: "EURO Standard MSRP",
        value: "euStdMsrpMercOverride",
    },
    { label: "Discount", value: "discount" },
    { label: "Store Price", value: "storePrice" },
    {
        label: "EURO Standard WHSL",
        value: "euStdWhslMercOverride",
    },
    {
        label: "DTC PROJECTION Units",
        value: "dtcProjUnitsMercOverride",
    },
    {
        label: "DTC ACTUAL Units",
        value: "dtcActualUnitsMercOverride",
    },
    {
        label: "WHSL PROJECTION Units",
        value: "whslProjUnitsMercOverride",
    },
    {
        label: "WHSL ACTUAL Units",
        value: "whslActualUnitsMercOverride",
    },
    { label: "DTC PROJECTION SALES", value: "salesDtcMercOverride" },
    { label: "DTC ACTUAL SALES", value: "salesActualDtcMercOverride" },
    { label: "WHSL PROJECTION Sales", value: "salesWhslMercOverride" },
    { label: "WHSL ACTUAL Sales", value: "salesActualWhslMercOverride" },
    { label: "DTC PROJECTION Margin", value: "marginDtcMercOverride" },
    { label: "DTC ACTUAL Margin", value: "marginActualDtcMercOverride" },
    {
        label: "WHSL PROJECTION Margin",
        value: "marginWhslMercOverride",
    },
    {
        label: "WHSL ACTUAL Margin",
        value: "marginActualWhslMercOverride",
    },
    {
        label: "DTC Margin %",
        value: "marginPercDtcMercOverride",
    },
    {
        label: "WHSL Margin %",
        value: "marginPercWhslMercOverride",
    },
];

export const dollarFormatter = ({ value }) =>
    value === null || value === undefined || isNaN(parseFloat(value))
        ? "-"
        : "$" + parseFloat(value)?.toFixed(2);
export const euroFormatter = ({ value }) =>
    value === null || value === undefined || isNaN(parseFloat(value))
        ? "-"
        : "€" + parseFloat(value)?.toFixed(2);
export const percentageFormatter = ({ value }) => {
    return value === null || value === undefined || isNaN(parseFloat(value))
        ? "-"
        : parseFloat(value)?.toFixed(2) + "%";
};

const numberSorting = (a, b) => {
    if (a === "-" || a === undefined || a === null) return -1;
    if (b === "-" || b === undefined || b === null) return 1;

    return a - b;
};

const dummyData = {
    source_material: "200631310003",
    market_name: "Pre-fall 2037",
    mapping_type: "Material",
    material_number: "200631310003",
    season_code: "241",
    planning_brand: "WW LAUREN",
    planning_sub_brand: "WW LAUREN RTW",
    id: 123865,
    uid: "4affff74-cf12-4ae1-8ff4-fcb54c510b77",
    price_file_id: 98,
    price_file_name: "PriceSmart_Workbench_Price_File_Template (56).xlsx",
    market_id: 44,
    region: "EMEA",
    channel: "OUTLET",
    special_attribute: null,
    parent_material: "",
    parent_season: "",
    price_establishment: "",
    type_of_pe: "",
    discount: null,
    store_price: null,
    similar_material: "200631310003",
    similar_material_season: "241",
    similar_material_channel: "OUTLET",
    similar_material_market_id: 39,
    similar_parent_material: null,
    pfs_was_material: "200631310003",
    pfs_was_material_season: "241",
    pfs_was_material_channel: "FULL-PRICE",
    pfs_was_material_market_id: 34,
    similar_material_update_type: null,
    pfs_was_material_update_type: null,
    image_url:
        "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1231691_lifestyle",
    season_name: "SPRING 2024",
    price_rule_id: 564,
    is_price_rule_applied: 1,
    price_rule_name: "PR_pre2027_V2_V2",
    discount_config_id: 75,
    price_rule_stage: 8,
    price_rule_status: 1,
    product_h4_id: "15",
    product_h4_name: "LAUREN RTW",
    product_h1_id: "200",
    product_h1_name: "W LRL APP MISSY RTW",
    product_h2_id: "02",
    product_h2_name: "WOMENS APPAREL",
    product_h3_id: "2",
    product_h3_name: "WOMENS",
    product_h5_id: "05",
    product_h5_name: "LAUREN RALPH LAUREN",
    product_h6_id: "A",
    product_h6_name: "APPAREL",
    product_h7_id: "10",
    product_h7_name: "MISSY",
    product_h8_id: "6",
    product_h8_name: "RTW",
    product_h9_id: "PN",
    product_h9_name: "PANTS",
    product_h10_id: "FFR",
    product_h10_name: "FLAT FRONT",
    product_h11_id: "AKL",
    product_h11_name: "ANKLE",
    product_h12_id: "W",
    product_h12_name: "WOMENS",
    product_h13_id: "APP",
    product_h13_name: "APPAREL",
    product_h14_id: "WAPP",
    product_h14_name: "W APPAREL",
    product_h15_id: "PANT",
    product_h15_name: "PANTS",
    product_h16_id: "FFR",
    product_h16_name: "FLAT FRONT",
    material_desc: "PLSHD BI STR TWLL-PANT",
    material_group: "WOVENS",
    material_group_code: "WVN",
    style_number: "200631310",
    color: "BLACK",
    color_code: "001",
    nrf_color_code: "001",
    merch_concept: "LRL SPRING SUMMER 24",
    merch_concept_code: "1108G",
    merch_fabric: "PLSHD BI STR TWLL",
    merch_fabric_code: "1026375",
    plan_size_group: "MISSY",
    plan_size_group_code: "MIS",
    product_label: "LAUREN",
    product_label_code: "13",
    collection: "W APP LAUREN",
    collection_code: "205",
    board_number: "B2410L14A",
    sap_status: "EXPORTED",
    gfe_pre_pack: "NORMAL",
    legacy_material_nbr: null,
    vendor_material_nbr: null,
    item_number: "631310",
    model_name: "PANT",
    upc_status: "UPCED",
    color_description: "BLACK",
    first_available_dt: "2024-02-01",
    ip_price_export_status: "EXPORTED",
    pattern: "-1",
    model_short_description: "KESLINA",
    model_long_description: "KESLINA-SKINNY-PANT",
    wholesale_effective_date: null,
    msrp_effective_date: null,
    approval_status: 0,
    gfe_material_status: "ACTIVE",
    msrp_price: 159,
    wholesale_price: null,
    itp_flag_dtc: null,
    itp_flag_whsl: null,
    std_cost: null,
    tfg_manual: null,
    fty_cost_manual: null,
    dtc_projection_units_ia: null,
    dtc_projection_units: null,
    dtc_actual_units: null,
    wholesale_projection_units_ia: null,
    wholesale_projection_units: null,
    wholesale_actual_units: null,
    sales_dtc_ia: null,
    sales_dtc_projected: null,
    sales_dtc_actual: null,
    sales_wholesale_ia: null,
    sales_wholesale_projected: null,
    sales_wholesale_actual: null,
    cost_for_margin: null,
    margin_dtc_ia: null,
    margin_dtc_projected: null,
    margin_dtc_actual: null,
    margin_wholesale_ia: null,
    margin_wholesale_projected: null,
    margin_wholesale_actual: null,
    margin_dtc_perc_ia: null,
    margin_dtc_perc: null,
    margin_wholesale_perc_ia: null,
    margin_wholesale_perc: null,
    us_msrp_amt: 125,
    us_whsl_amt: 51.88,
    short_board: "B0L14A",
    discount_table: null,
    current_selection: false,
};

const defaultColumnDefs = [
    {
        headerName: "",
        field: "",
        pinned: true,
        lockPinned: true,
        sortable: false,
        filter: false,
        checkboxSelection: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        resizable: false,
        menuTabs: [],
        width: 50,
        cellClass: styles["dark-cell"],
        valueFormatter: () => "",
    },
    { headerName: "MARKET", hide: true, field: "market" },
    { headerName: "REGION", hide: true, field: "region" },
    { headerName: "CHANNEL", hide: true, field: "channel" },
    { headerName: "ALERTS", hide: true, field: "alerts" },
    { headerName: "EXCEPTIONS", hide: true, field: "exceptions" },
    {
        headerName: "MATERIAL NUMBER",
        field: "material_number",
        pinned: true,
        lockPinned: true,
        suppressMovable: true,
        cellClass: "bigNumber",
        cellRenderer: MaterialAlertException,
        cellRendererParams: { section: "alertsAndExceptions" },
        width: 260,
    },
];

const emptyColumn = {
    headerName: "",
    field: "",
    sortable: false,
    filter: false,
    resizable: false,
    cellStyle: { width: "5px" },
    valueFormatter: () => "",
};

export const getColPinValue = (pinned) =>
    pinned === "right" ? "right" : pinned ? "left" : null;

export const rightPinCellStyle = {
    backgroundColor: "#f7f7f7",
    borderTopColor: "#fff",
    borderBottomColor: "rgb(226, 226, 226)",
};

const DateCellEditor = forwardRef((props, ref) => {
    const refInput = useRef(null);

    const [value, setValue] = useState(moment(props.value));
    const [openCalendar, setOpenCalendar] = useState(false);

    useEffect(() => {
        refInput.current.focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value.format("MM/DD/YYYY");
            },

            isCancelBeforeStart() {
                return false;
            },

            isCancelAfterEnd() {
                return false;
            },
        };
    });

    const onChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                ref={refInput}
                value={value}
                open={openCalendar}
                closeOnSelect={false}
                format="MM/DD/YYYY"
                slots={{
                    actionBar: () => (
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setValue(moment(props.value));
                                    setOpenCalendar(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => setOpenCalendar(false)}
                            >
                                Apply
                            </Button>
                        </DialogActions>
                    ),
                }}
                onOpen={() => setOpenCalendar(true)}
                onClose={() => setOpenCalendar(false)}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
});

const excelStyles = [
    {
        id: "bigNumber",
        numberFormat: {
            format: "0",
        },
    },
];

const rowExpandeEvent = ({ rowIndex, isOpen }) => {
    if (rowIndex == null || rowIndex == undefined) return;

    const rowNode = gridRef?.current?.api.getDisplayedRowAtIndex(rowIndex);
    rowNode?.setExpanded(isOpen);

    // console.log({ rowIndex, isOpen, rowNode });
};

const asynWaiting = async (ms = 3000) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });

const InnerTableToggleCell = (props) => {
    // console.log(props)
    const { data, node, value, type, rowIndex, toggleCellType, showAuditLog } =
        props;
    const [isOpen, setIsOpen] = useState(false);
    const {
        general: { innerTableToggleObject },
    } = useSelector((state) => state.workbench);

    const isSameRowWithDifferentTypeOpen =
        innerTableToggleObject?.rowIndex == rowIndex &&
        innerTableToggleObject?.toggleCellType !== toggleCellType &&
        innerTableToggleObject.isOpen;

    const dispatch = useDispatch();

    const isSimilar = toggleCellType == "similarMaterial";

    const updateType = isSimilar
        ? data.similar_material_update_type
        : data.pfs_was_material_update_type;

    useEffect(() => {
        if (
            innerTableToggleObject?.rowIndex !== rowIndex ||
            innerTableToggleObject?.toggleCellType !== toggleCellType
        ) {
            setIsOpen(false);
        }
    }, [innerTableToggleObject]);

    const onToggleCollapse = async () => {
        if (isSameRowWithDifferentTypeOpen) return;
        const isOpenTemp = !isOpen;

        if (isOpenTemp && innerTableToggleObject?.rowIndex != rowIndex) {
            rowExpandeEvent({
                rowIndex: innerTableToggleObject?.rowIndex,
                isOpen: false,
            });
        }

        dispatch(
            setInnerTableToggleObject({
                rowIndex,
                toggleCellType,
                isOpen: isOpenTemp,
            })
        );

        setIsOpen(isOpenTemp);
        rowExpandeEvent({ rowIndex, isOpen: isOpenTemp });

        // if(
        //     isOpenTemp &&
        //     innerTableToggleObject?.rowIndex == rowIndex &&
        //     innerTableToggleObject?.toggleCellType !== toggleCellType
        // ) {
        //     rowExpandeEvent({ rowIndex, isOpen: false });

        //     await asynWaiting;

        //     rowExpandeEvent({ rowIndex, isOpen: true });
        // } else {
        //     rowExpandeEvent({ rowIndex, isOpen: isOpenTemp });
        // }
    };

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
                className="inner-table-toggle-button"
                style={{
                    color: isSameRowWithDifferentTypeOpen
                        ? "#1d1d1d00"
                        : "#1d1d1d",
                }}
                onClick={onToggleCollapse}
            >
                {isOpen ? (
                    <KeyboardArrowDownIcon style={{ width: 20 }} />
                ) : (
                    <KeyboardArrowRightIcon style={{ width: 20 }} />
                )}
            </div>

            <div>
                <Button
                    variant="text"
                    onClick={() => {
                        showAuditLog({
                            data: node.data,
                            value,
                            type: "MSRP",
                            logType: toggleCellType,
                            screen: "base-pricing",
                        });
                    }}
                >
                    {value || "-"}
                </Button>
            </div>
            {updateType && (
                <Tooltip title={updateType}>
                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                        <Chip
                            label={updateType.charAt(0)}
                            color="primary"
                            size="small"
                        />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

InnerTableToggleCell.propTypes = {
    rowIndex: PropTypes.number,
    toggleCellType: PropTypes.string,
};

export const attributeColumnDefMap = {
    productImage: {
        flag: true,
        columnDef: {
            headerName: "IMAGE",
            field: "image_url",
            pinned: true,
            lockPinned: true,
            sortable: false,
            suppressMovable: true,
            filter: false,
            cellStyle: {
                display: "flex",
                justifyContent: "center",
            },
            cellRenderer: (data) => <PriceListLevelImageCell data={data} />,
            width: 90,
            tooltipComponent: ImageTooltipRenderer,
            tooltipField: "image_url",
        },
    },

    similarMaterial: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "Similar Material(SM)",
            field: "similar_material",
            cellStyle: {
                background: "#F7F7F7",
            },
            cellRenderer: InnerTableToggleCell,
            cellRendererParams: {
                toggleCellType: "similarMaterial",
            },
        },
    },
    smImage: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "SM Image",
            field: "similar_material_image_url",
            cellStyle: {
                display: "flex",
                justifyContent: "center",
                background: "#F7F7F7",
                // width: "200px",
            },
            cellRenderer: (data) => <PriceListLevelImageCell data={data} />,
            // width: 200,
            tooltipComponent: ImageTooltipRenderer,
            tooltipField: "similar_material_image_url",
        },
    },
    smSeason: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "SM Season",
            field: "similar_material_season",
            cellStyle: {
                background: "#F7F7F7",
            },
        },
    },
    smMarket: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "SM Market",
            field: "similar_material_market_name",
            cellStyle: {
                background: "#F7F7F7",
            },
        },
    },
    smChannel: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "SM Channel",
            field: "similar_material_channel",
            cellStyle: {
                background: "#F7F7F7",
            },
        },
    },

    pfsWasMaterial: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "PFS WAS Material",
            field: "pfs_was_material",
            cellStyle: {
                background: "#F7F7F7",
            },
            cellRenderer: InnerTableToggleCell,
            cellRendererParams: {
                toggleCellType: "pfsWasMaterial",
            },
        },
    },
    pfsWasImage: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "PFS Was Image",
            field: "pfs_was_image_url",
            cellStyle: {
                display: "flex",
                justifyContent: "center",
                background: "#F7F7F7",
                // width: "200px",
            },
            cellRenderer: (data) => <PriceListLevelImageCell data={data} />,
            // width: 200,
            tooltipComponent: ImageTooltipRenderer,
            tooltipField: "pfs_was_image_url",
        },
    },
    pfsWasSeason: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "PFS WAS Season",
            field: "pfs_was_material_season",
            cellStyle: {
                background: "#F7F7F7",
            },
        },
    },
    pfsWasMarket: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "PFS WAS Market",
            field: "pfs_was_market_name",
            cellStyle: {
                background: "#F7F7F7",
            },
        },
    },
    pfsWasIndicated: {
        flag: true,
        type: "similarOrPfs",
        columnDef: {
            headerName: "PFS WAS Indicated",
            field: "pfs_was_indicative",
            cellStyle: {
                background: "#F7F7F7",
            },
        },
    },

    approvalStatus: {
        flag: true,
        columnDef: {
            headerName: "APPROVAL STATUS",
            field: "approval_status",
            showByDefault: true,
            cellRenderer: ApprovalStatusRenderer,
            filterValueGetter({ data }) {
                if (
                    data.approval_status === null ||
                    data.approval_status === undefined
                )
                    return "";
                return APPROVAL_STATUS[data.approval_status];
            },
        },
    },
    seasonCode: {
        flag: true,
        columnDef: {
            headerName: "SEASON CODE",
            field: "season_code",
        },
    },
    specialAttribute: {
        flag: true,
        columnDef: {
            headerName: "SPECIAL ATTRIBUTE",
            field: "special_attribute",
        },
    },
    seasonName: {
        flag: true,
        columnDef: {
            headerName: "SEASON NAME",
            field: "season_name",
        },
    },
    parentMaterial: {
        flag: true,
        columnDef: {
            headerName: "Parent Material Number",
            field: "parent_material",
        },
    },
    parentSeason: {
        flag: true,
        columnDef: {
            headerName: "PARENT SEASON CODE",
            field: "parent_season",
        },
    },
    priceEstablishment: {
        flag: true,
        columnDef: {
            headerName: "PRICE ESTABLISHMENT",
            field: "price_establishment",
        },
    },
    typeOfPE: {
        flag: true,
        columnDef: {
            headerName: "Type Of PE",
            field: "type_of_pe",
        },
    },
    discountTable: {
        flag: true,
        columnDef: {
            headerName: "Discount Table",
            field: "discount_table",
            cellRenderer: DiscountTableLinkComponent,
            comparator: (a, b) =>
                a.toLowerCase().localeCompare(b.toLowerCase()),
        },
    },
    discount: {
        flag: true,
        columnDef: {
            headerName: "Discount",
            field: "discount",
            headerClass: "ag-grid-column-group-header",
            cellRenderer: PriceEditComponent,
            filterParams: numberColFilterParams,
            comparator: numberSorting,
        },
    },
    storePrice: {
        flag: true,
        columnDef: {
            headerName: "Store Price",
            field: "store_price",
            headerClass: "ag-grid-column-group-header",
            cellRenderer: PriceEditComponent,
            filterParams: numberColFilterParams,
            comparator: numberSorting,
        },
    },

    priceRule: {
        flag: true,
        columnDef: {
            headerName: "PRICE RULE",
            field: "price_rule_name",
            cellRenderer: priceRuleLinkRenderer,
        },
    },
    is_price_rule_applied: {
        flag: true,
        columnDef: {
            headerName: "ACTION REQUIRED",
            field: "is_price_rule_applied",
            cellRenderer: ({ value }) => {
                return actionRequired[value]?.text || "-";
            },
            filterValueGetter: (data) =>
                actionRequired[data.getValue("is_price_rule_applied")]?.text ||
                "-",
        },
    },
    planningBrand: {
        flag: true,
        columnDef: {
            headerName: "PLANNING BRAND",
            field: "planning_brand",
            ...hierarchyColDef,
        },
    },
    planningSubBrand: {
        flag: true,
        columnDef: {
            headerName: "PLANNING SUB BRAND",
            field: "planning_sub_brand",
            ...hierarchyColDef,
        },
    },
    brandGrp: {
        flag: true,
        columnDef: {
            headerName: "BRAND GROUP",
            field: "product_h4_name",
            ...hierarchyColDef,
        },
    },
    brandGrpId: {
        flag: true,
        columnDef: {
            headerName: "BRAND GROUP ID",
            field: "product_h4_id",
            ...hierarchyColDef,
        },
    },
    merchDiv: {
        flag: true,
        columnDef: {
            headerName: "MERCH DIVISION",
            field: "product_h1_name",
            ...hierarchyColDef,
        },
    },
    merchDivId: {
        flag: true,
        columnDef: {
            headerName: "MERCH DIVISION ID",
            field: "product_h1_id",
            ...hierarchyColDef,
        },
    },
    merchOrg: {
        flag: true,
        columnDef: {
            headerName: "MERCH ORG",
            field: "product_h2_name",
            ...hierarchyColDef,
        },
    },
    merchOrgCode: {
        flag: true,
        columnDef: {
            headerName: "MERCH ORG CODE",
            field: "product_h2_id",
            ...hierarchyColDef,
        },
    },
    gender: {
        flag: true,
        columnDef: {
            headerName: "GENDER",
            field: "product_h3_name",
            ...hierarchyColDef,
        },
    },
    genderCode: {
        flag: true,
        columnDef: {
            headerName: "GENDER CODE",
            field: "product_h3_id",
            ...hierarchyColDef,
        },
    },
    brand: {
        flag: true,
        columnDef: {
            headerName: "BRAND",
            field: "product_h5_name",
            ...hierarchyColDef,
        },
    },
    brandId: {
        flag: true,
        columnDef: {
            headerName: "BRAND ID",
            field: "product_h5_id",
            ...hierarchyColDef,
        },
    },
    merchSeg: {
        flag: true,
        columnDef: {
            headerName: "MERCH SEGMENT",
            field: "product_h6_name",
            ...hierarchyColDef,
        },
    },
    merchSegCode: {
        flag: true,
        columnDef: {
            headerName: "MERCH SEGMENT CODE",
            field: "product_h6_id",
            ...hierarchyColDef,
        },
    },
    merchSizeGrp: {
        flag: true,
        columnDef: {
            headerName: "MERCH SIZE GROUP",
            field: "product_h7_name",
            ...hierarchyColDef,
        },
    },
    merchSizeGrpCode: {
        flag: true,
        columnDef: {
            headerName: "MERCH SIZE GROUP CODE",
            field: "product_h7_id",
            ...hierarchyColDef,
        },
    },
    merchCat: {
        flag: true,
        columnDef: {
            headerName: "MERCH CATEGORY",
            field: "product_h8_name",
            ...hierarchyColDef,
        },
    },
    merchCatCode: {
        flag: true,
        columnDef: {
            headerName: "MERCH CATEGORY CODE",
            field: "product_h8_id",
            ...hierarchyColDef,
        },
    },
    prodCat: {
        flag: true,
        columnDef: {
            headerName: "PRODUCT CATEGORY",
            field: "product_h9_name",
            ...hierarchyColDef,
        },
    },
    prodCatCode: {
        flag: true,
        columnDef: {
            headerName: "PRODUCT CATEGORY CODE",
            field: "product_h9_id",
            ...hierarchyColDef,
        },
    },
    prodClass: {
        flag: true,
        columnDef: {
            headerName: "PRODUCT CLASS",
            field: "product_h10_name",
            ...hierarchyColDef,
        },
    },
    prodClassCode: {
        flag: true,
        columnDef: {
            headerName: "PRODUCT CLASS CODE",
            field: "product_h10_id",
            ...hierarchyColDef,
        },
    },
    prodSubclass: {
        flag: true,
        columnDef: {
            headerName: "PRODUCT SUBCLASS",
            field: "product_h11_name",
            ...hierarchyColDef,
        },
    },
    prodSubclassCode: {
        flag: true,
        columnDef: {
            headerName: "PRODUCT SUBCLASS CODE",
            field: "product_h11_id",
            ...hierarchyColDef,
        },
    },
    planLvl1: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 1",
            field: "product_h12_name",
            ...hierarchyColDef,
        },
    },
    planLvl1Code: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 1 CODE",
            field: "product_h12_id",
            ...hierarchyColDef,
        },
    },
    planLvl2: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 2",
            field: "product_h13_name",
            ...hierarchyColDef,
        },
    },
    planLvl2Code: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 2 CODE",
            field: "product_h13_id",
            ...hierarchyColDef,
        },
    },
    planLvl3: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 3",
            field: "product_h14_name",
            ...hierarchyColDef,
        },
    },
    planLvl3Code: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 3 CODE",
            field: "product_h14_id",
            ...hierarchyColDef,
        },
    },
    planLvl4: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 4",
            field: "product_h15_name",
            ...hierarchyColDef,
        },
    },
    planLvl4Code: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 4 CODE",
            field: "product_h15_id",
            ...hierarchyColDef,
        },
    },
    planLvl5: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 5",
            field: "product_h16_name",
            ...hierarchyColDef,
        },
    },
    planLvl5Code: {
        flag: true,
        columnDef: {
            headerName: "PLAN LEVEL 5 CODE",
            field: "product_h16_id",
            ...hierarchyColDef,
        },
    },
    matDesc: {
        flag: true,
        columnDef: {
            headerName: "MATERIAL DESCRIPTION",
            field: "material_desc",
        },
    },
    matGrp: {
        flag: true,
        columnDef: {
            headerName: "MATERIAL GROUP",
            field: "material_group",
        },
    },
    matGrpCode: {
        flag: true,
        columnDef: {
            headerName: "MATERIAL GROUP CODE",
            field: "material_group_code",
        },
    },
    styleNum: {
        flag: true,
        columnDef: {
            headerName: "STYLE NUMBER",
            field: "style_number",
        },
    },
    color_description: {
        flag: true,
        columnDef: {
            headerName: "COLOR DESCRIPTION",
            field: "color_description",
        },
    },
    color: {
        flag: true,
        columnDef: {
            headerName: "COLOR",
            field: "color",
        },
    },
    colorCode: {
        flag: true,
        columnDef: {
            headerName: "COLOR CODE",
            field: "color_code",
        },
    },
    nrfColorCode: {
        flag: true,
        columnDef: {
            headerName: "NRF COLOR CODE",
            field: "nrf_color_code",
        },
    },
    merchConcept: {
        flag: true,
        columnDef: {
            headerName: "MERCH CONCEPT",
            field: "merch_concept",
        },
    },
    merchConceptCode: {
        flag: true,
        columnDef: {
            headerName: "MERCH CONCEPT CODE",
            field: "merch_concept_code",
        },
    },
    merchFabric: {
        flag: true,
        columnDef: {
            headerName: "MERCH FABRIC",
            field: "merch_fabric",
        },
    },
    merchFabricCode: {
        flag: true,
        columnDef: {
            headerName: "MERCH FABRIC CODE",
            field: "merch_fabric_code",
        },
    },
    planSizeGrp: {
        flag: true,
        columnDef: {
            headerName: "PLAN SIZE GROUP",
            field: "plan_size_group",
        },
    },
    planSizeGrpCode: {
        flag: true,
        columnDef: {
            headerName: "PLAN SIZE GROUP CODE",
            field: "plan_size_group_code",
        },
    },
    prodlabel: {
        flag: true,
        columnDef: {
            headerName: "PRODUCT LABEL",
            field: "product_label",
        },
    },
    prodLabelCode: {
        flag: true,
        columnDef: {
            headerName: "PRODUCT LABEL CODE",
            field: "product_label_code",
        },
    },
    pattern: {
        flag: true,
        columnDef: {
            headerName: "PATTERN",
            field: "pattern",
        },
    },
    collection: {
        flag: true,
        columnDef: {
            headerName: "COLLECTION",
            field: "collection",
        },
    },
    collectionCode: {
        flag: true,
        columnDef: {
            headerName: "COLLECTION CODE",
            field: "collection_code",
        },
    },
    boardNumber: {
        flag: true,
        columnDef: {
            headerName: "BOARD NUMBER",
            field: "board_number",
        },
    },
    shortBoard: {
        flag: true,
        columnDef: {
            headerName: "SHORT BOARD",
            field: "short_board",
        },
    },
    sapStatus: {
        flag: true,
        columnDef: {
            headerName: "SAP STATUS",
            field: "sap_status",
        },
    },
    upc_status: {
        flag: true,
        columnDef: {
            headerName: "UPC STATUS",
            field: "upc_status",
        },
    },
    ip_price_export_status: {
        flag: true,
        columnDef: {
            headerName: "IP PRICE EXPORT STATUS",
            field: "ip_price_export_status",
        },
    },
    gfePrePack: {
        flag: true,
        columnDef: {
            headerName: "GFE PRE-PACK",
            field: "gfe_pre_pack",
        },
    },
    legacyMatNum: {
        flag: true,
        columnDef: {
            headerName: "LEGACY MATERIAL NUMBER",
            field: "legacy_material_nbr",
            cellClass: "bigNumber",
        },
    },
    vendor_material_nbr: {
        flag: true,
        columnDef: {
            headerName: "VENDOR MATERIAL NUMBER",
            field: "vendor_material_nbr",
        },
    },
    item_number: {
        flag: true,
        columnDef: {
            headerName: "ITEM NUMBER",
            field: "item_number",
        },
    },
    model_name: {
        flag: true,
        columnDef: {
            headerName: "MODEL NAME",
            field: "model_name",
        },
    },
    model_long_desc: {
        flag: true,
        columnDef: {
            headerName: "MODEL LONG DESCRIPTION",
            field: "model_long_description",
        },
    },
    model_desc: {
        flag: true,
        columnDef: {
            headerName: "MODEL SHORT DESCRIPTION",
            field: "model_short_description",
        },
    },
    first_available_dt: {
        flag: true,
        columnDef: {
            headerName: "FIRST AVAILABLE DATE",
            field: "first_available_dt",
            cellClass: "center-center",
            comparator: dateComparator,
        },
    },
    rtlPriceEffectiveDate: {
        flag: true,
        columnDef: {
            headerName: "MSRP PRICE EFFECTIVE DATE",
            field: "msrp_effective_date",
            cellRenderer: DateEditComponent,
            comparator: dateComparator,
        },
    },
    whslPriceEffectiveDate: {
        flag: true,
        columnDef: {
            headerName: "WHSL PRICE EFFECTIVE DATE",
            field: "wholesale_effective_date",
            cellRenderer: DateEditComponent,
            comparator: dateComparator,
        },
    },
    gfeMatStatus: {
        flag: true,
        columnDef: {
            headerName: "GFE MATERIAL STATUS",
            field: "gfe_material_status",
        },
    },
    dtcItpFlag: {
        flag: true,
        columnDef: {
            headerName: "DTC ITP FLAG",
            field: "itp_flag_dtc",
        },
    },
    whslItpFlag: {
        flag: true,
        columnDef: {
            headerName: "WHSL ITP FLAG",
            field: "itp_flag_whsl",
        },
    },
    regStdCost: {
        flag: true,
        columnDef: {
            headerName: "REGIONAL STANDARD COST",
            field: "std_cost",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    regTfgMan: {
        flag: true,
        columnDef: {
            headerName: "REGIONAL TFG MANUAL (EUR)",
            field: "tfg_manual",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    costOrigin: {
        flag: true,
        columnDef: {
            headerName: "COST ORIGIN",
            field: "costOrigin",
        },
    },
    costUsed: {
        flag: true,
        columnDef: {
            headerName: "COST USED",
            field: "costUsed",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    factoryCostMan: {
        flag: true,
        columnDef: {
            headerName: "FACTORY COST MANUAL (USD)",
            field: "fty_cost_manual",
            valueFormatter: dollarFormatter,
            filterParams: numberColFilterParams,
        },
    },
    usMsrp: {
        flag: true,
        columnDef: {
            headerName: "US MSRP",
            field: "us_msrp_amt",
            valueFormatter: dollarFormatter,
            filterParams: numberColFilterParams,
        },
    },
    usWhsl: {
        flag: true,
        columnDef: {
            headerName: "US WHSL",
            field: "us_whsl_amt",
            valueFormatter: dollarFormatter,
            filterParams: numberColFilterParams,
        },
    },
    euStdMsrpMercOverride: {
        flag: true,
        columnDef: {
            headerName: "EURO STANDARD MSRP (EUR)",
            field: "msrp_price",
            headerClass: "ag-grid-column-group-header",
            cellRenderer: PriceEditComponent,
            filterParams: numberColFilterParams,
            comparator: numberSorting,
        },
    },
    euStdWhslMercOverride: {
        flag: true,
        columnDef: {
            headerName: "EURO STANDARD WHSL (EUR)",
            field: "wholesale_price",
            headerClass: "ag-grid-column-group-header",
            cellRenderer: PriceEditComponent,
            filterParams: numberColFilterParams,
        },
    },
    dtcProjUnitsMercOverride: {
        flag: true,
        columnDef: {
            headerName: "DTC PROJECTION UNITS",
            field: "dtc_projection_units",
            filterParams: numberColFilterParams,
        },
    },
    dtcActualUnitsMercOverride: {
        flag: true,
        columnDef: {
            headerName: "DTC ACTUAL UNITS",
            field: "dtc_actual_units",
            filterParams: numberColFilterParams,
        },
    },
    whslProjUnitsMercOverride: {
        flag: true,
        columnDef: {
            headerName: "WHSL PROJECTION UNITS",
            field: "wholesale_projection_units",
            filterParams: numberColFilterParams,
        },
    },
    whslActualUnitsMercOverride: {
        flag: true,
        columnDef: {
            headerName: "WHSL ACTUAL UNITS",
            field: "wholesale_actual_units",
            filterParams: numberColFilterParams,
        },
    },
    salesDtcMercOverride: {
        flag: true,
        columnDef: {
            headerName: "DTC PROJECTION SALES",
            field: "sales_dtc_projected",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    salesActualDtcMercOverride: {
        flag: true,
        columnDef: {
            headerName: "DTC ACTUAL SALES",
            field: "sales_dtc_actual",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    salesWhslMercOverride: {
        flag: true,
        columnDef: {
            headerName: "WHSL PROJECTION SALES",
            field: "sales_wholesale_projected",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    salesActualWhslMercOverride: {
        flag: true,
        columnDef: {
            headerName: "WHSL ACTUAL SALES",
            field: "sales_wholesale_actual",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    marginDtcMercOverride: {
        flag: true,
        columnDef: {
            headerName: "DTC PROJECTION MARGIN",
            field: "margin_dtc_projected",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    marginActualDtcMercOverride: {
        flag: true,
        columnDef: {
            headerName: "DTC ACTUAL MARGIN",
            field: "margin_dtc_actual",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    marginWhslMercOverride: {
        flag: true,
        columnDef: {
            headerName: "WHSL PROJECTION MARGIN",
            field: "margin_wholesale_projected",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    marginActualWhslMercOverride: {
        flag: true,
        columnDef: {
            headerName: "WHSL ACTUAL MARGIN",
            field: "margin_wholesale_actual",
            valueFormatter: euroFormatter,
            filterParams: numberColFilterParams,
        },
    },
    marginPercDtcMercOverride: {
        flag: true,
        columnDef: {
            headerName: "DTC MARGIN %",
            field: "margin_dtc_perc",
            valueFormatter: percentageFormatter,
            filterParams: numberColFilterParams,
        },
    },
    marginActualPercDtcMercOverride: {
        flag: true,
        columnDef: {
            headerName: "DTC MARGIN %",
            field: "margin_dtc_actual",
            valueFormatter: percentageFormatter,
            filterParams: numberColFilterParams,
        },
    },
    marginPercWhslMercOverride: {
        flag: true,
        columnDef: {
            headerName: "WHSL MARGIN %",
            field: "margin_wholesale_perc",
            valueFormatter: percentageFormatter,
            filterParams: numberColFilterParams,
        },
    },
    marginActualPerWhslMercOverride: {
        flag: true,
        columnDef: {
            headerName: "WHSL ACTUAL MARGIN %",
            field: "margin_wholesale_perc",
            valueFormatter: percentageFormatter,
            filterParams: numberColFilterParams,
        },
    },
};

const columnFieldObjKeyMap = {
    rtlPriceEffectiveDate: "msrp_effective_date",
    whslPriceEffectiveDate: "wholesale_effective_date",
    euStdMsrpMercOverride: "msrp_price",
    discount: "discount",
    storePrice: "store_price",
};

const validateMaterial = (material) => {
    if (!material) {
        return { flag: false, message: "Invalid material" };
    }

    const { msrp_price } = material;

    const errors = [];
    let flag = true;
    let message = "";

    const parsedPrice = parseFloat(msrp_price);

    if (
        isNaN(parsedPrice) ||
        parsedPrice <= 0 ||
        parsedPrice > 9999999999999.99
    ) {
        flag = false;
        errors.push("EURO Standard MSRP");
    }

    if (!flag) {
        message = `${errors.join(", ")} ${
            errors.length > 1 ? "are" : "is"
        } not a valid entry`;
    }

    return { flag, message };
};

export const isMaterialWithoutMandatoryException = (
    data,
    alertsAndExceptions
) => {
    if (!alertsAndExceptions[data.material_number + data.season_code]) {
        return true;
    }

    return (
        alertsAndExceptions[data.material_number + data.season_code]
            .filter((alertException) => alertException.entry_type === 2)
            .findIndex((exception) => exception.is_mandatory === 1) === -1
    );
};

function DateEditComponent(props) {
    const {
        custom: {
            editMode,
            selectedNode,
            onChange,
            editData,
            type,
            showAuditLog,
        },
        column: { colId },
        node,
        value,
    } = props;

    return editMode && selectedNode === node ? (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                value={moment(editData?.[colId] ?? "")}
                closeOnSelect
                format="MM/DD/YYYY"
                onChange={(newValue) => {
                    onChange(newValue?.format("MM/DD/YYYY"));
                }}
            />
        </LocalizationProvider>
    ) : (
        <Button
            variant="text"
            onClick={() => {
                showAuditLog({
                    data: node.data,
                    value,
                    type,
                    logType: "effective-date",
                    screen: "base-pricing",
                });
            }}
        >
            {value && value !== "-"
                ? moment(value).format("MM/DD/YYYY")
                : value}
        </Button>
    );
}

function PriceEditComponent(props) {
    const {
        colDef,
        custom: {
            editData,
            editMode,
            selectedNode,
            onChange,
            type,
            showAuditLog,
        },
        column: { colId },
        node,
        value,
    } = props;

    const {
        general: { isHistoricalData, selectedChannels },
    } = useSelector((state) => state.workbench);

    let rangeLimit = {};

    if (colDef.field === "discount") {
        rangeLimit = {
            inputProps: {
                min: 1,
                max: 99,
            },
        };
    }

    const onPriceChange = (e) => {
        const newValue = e.target.value;
        onChange(newValue);
    };

    let auditLogsParams = {
        data: node.data,
        value,
        price_list_name:
            selectedChannels[0].label === "FULL-PRICE"
                ? "BASE_PRICE_EURO STANDARD"
                : "BASE_PRICE_PFS_WAS",
        type,
        logType: "price",
        screen: "base-pricing",
    };

    if (colDef.field === "discount") {
        auditLogsParams.price_list_name = "BASE_PRICE_DISCOUNT";
        auditLogsParams.logType = "discount";
    }

    if (colDef.field === "store_price") {
        auditLogsParams.price_list_name = "BASE_PRICE_STORE_PRICE";
        auditLogsParams.logType = "store_price";
    }

    if (editMode && selectedNode === node && colDef.field !== "store_price")
        return (
            <TextField
                label=""
                type="number"
                value={editData?.[colId]}
                onChange={onPriceChange}
                {...rangeLimit}
            />
        );

    const getFormattedNumber = () => {
        if ((value || value === 0) && value !== "-") {
            return colDef.field === "discount"
                ? `${value}%`
                : euroFormatter(props);
        }

        return "-";
    };

    if (!isHistoricalData)
        return (
            <Button
                variant="text"
                onClick={() => {
                    showAuditLog(auditLogsParams);
                }}
            >
                {getFormattedNumber()}
            </Button>
        );
    return getFormattedNumber();
}

PriceEditComponent.propTypes = {
    colDef: PropTypes.any,
    custom: {
        editData: PropTypes.object,
        editMode: PropTypes.bool,
        selectedNode: PropTypes.object,
        onChange: PropTypes.func,
        type: PropTypes.string,
        showAuditLog: PropTypes.bool,
    },
    column: { colId: PropTypes.string },
    node: PropTypes.any,
    value: PropTypes.any,
};

let savedFilters = { list: {} };
const innerTableMemomisedData = {};
let currentPageGlobal;
function EUPriceListLevelPrices(props) {
    const { page, filterPage, onReuploadPriceFileClick, currentTab } = props;

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [resetAnchorEl, setResetAnchorEl] = useState(null);
    const [isAnyFilterApplied, setIsAnyFilterApplied] = useState(false);
    const downloadMenuOpen = Boolean(anchorEl);
    const resetMenuOpen = Boolean(resetAnchorEl);
    savedFilters.list = useSelector((state) => {
        return state.filters.savedFilters[filterPage];
    });
    gridRef = useRef();

    const [showUntagMaterialModal, setShowUntagMaterialModal] = useState(false);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [materialEditMode, setMaterialEditMode] = useState(false);
    const [editMaterialData, setEditMaterialData] = useState(null);
    const [isEditMaterialDataValid, setIsEditMaterialDataValid] =
        useState(false);
    const [materials, setMaterials] = useState([]);
    const [materialsForPriceGeneration, setMaterialsForPriceGeneration] =
        useState([]);
    const [confirmDateChangeModalOpen, setConfirmDateChangeModalOpen] =
        useState(false);
    const [bulkEditModalOpen, setBulkEditModalOpen] = useState(false);
    const [specialAttributeModalOpen, setSpecialAttributeModalOpen] =
        useState(false);
    const [newSpecialAttribute, setNewSpecialAttribute] = useState("");
    const [dateChangeMode, setDateChangeMode] = useState(null);
    const [isDateChangeEnabled, setIsDateChangeEnabled] = useState(false);
    const [rtlDateChangeMode, setRtlDateChangeMode] = useState("reset");
    const [rtlDateChangeValue, setRtlDateChangeValue] = useState();
    const [whslDateChangeMode, setWhslDateChangeMode] = useState("reset");
    const [whslDateChangeValue, setWhslDateChangeValue] = useState();
    const [isPriceChangeEnabled, setIsPriceChangeEnabled] = useState(false);
    const [isDiscountChangeEnabled, setIsDiscountChangeEnabled] =
        useState(false);
    const [discountChangeValue, setDiscountChangeValue] = useState(1);
    const [priceChangeValue, setPriceChangeValue] = useState(1);

    const [
        confirmGenerateCountryLevelPriceModalOpen,
        setConfirmGenerateCountryLevelPriceModalOpen,
    ] = useState(false);
    const [
        confirmGenerateCountryLevelPriceModalText,
        setConfirmGenerateCountryLevelPriceModalText,
    ] = useState("");
    const [exceptionOptions, setExceptionOptions] = useState([]);
    const [selectedExceptionOptions, setSelectedExceptionOptions] = useState(
        []
    );
    const [alertOptions, setAlertOptions] = useState([]);
    const [selectedAlertOptions, setSelectedAlertOptions] = useState([]);

    const [isAuditLogModalOpen, setIsAuditLogModalOpen] = useState(false);
    const [auditLogSrc, setAuditLogSrc] = useState({});

    const [appliedTableSettings, setAppliedTableSettings] = useState(null);
    const [showTableSettings, setShowTableSettings] = useState(false);
    const [showDiscountModel, setShowDiscountModal] = useState(false);

    const showAuditLog = useCallback((tempDt) => {
        setAuditLogSrc(tempDt);
        setIsAuditLogModalOpen(true);
    }, []);

    const {
        general: {
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId,
            alertsAndExceptions,
            countryPriceRequestMaterials,
            countryPriceRequestOperations,
            selectedTab,
            columnSettings,
            userResizedColumns,
            isHistoricalData,
            selectedViewIndex,
            innerTableToggleObject,
        },
        [page]: {
            materials: storeMaterials,
            countryLevelPrices,
            editedMaterials: editedMaterialsFromStore,
            selectedPlanGroup,
            currentPage,
            selectedAttributeOptions,
            selectedSeparatorOptions,
            pasteAreaValue,
            validValues,
            invalidValues,
        },
    } = useSelector((state) => state.workbench);
    const selectedFilters = useSelector(
        (state) => state.filters.savedFilters[filterPage]
    );
    const {
        userPermissions: {
            role_details,
            allowed_regions,
            allowed_channel_hierarchies,
            allowed_feature_actions: { eu_price_planning: permissions },
        },
        compLoading: {
            basePricing: basePricingLoader,
            basePricingAlertsConfig: basePricingAlertsConfigLoader,
            similarAndPfsMaterials: similarAndPfsMaterialsLoader,
        },
        triggerUnsavedEdit,
    } = useSelector((state) => state.global);

    const [
        rowCount,
        selectedRowCount,
        onTableModelUpdate,
        onTableSelectChange,
    ] = useTableRowCount();

    useEffect(() => {
        onTableSettingsApply(
            tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS,
            columnSettings[tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS][
                selectedViewIndex[tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS]
            ]
        );

        currentPageGlobal = page;

        return () => {
            dispatch(setEditedMaterial({ page, data: [] }));
        };
    }, []);

    const isMaterialSelectable = useCallback(
        (data) => {
            return (
                !checkIfMaterialProcessedForCountryPrice(
                    data.id,
                    countryPriceRequestMaterials
                ) &&
                checkMaterialSelectionValidity(
                    role_details,
                    allowed_regions,
                    allowed_channel_hierarchies,
                    data
                ) &&
                !isMaterialBeingSynced(data, countryLevelPrices) &&
                checkMaterialApprovalStatus(data)
            );
        },
        [
            allowed_channel_hierarchies,
            allowed_regions,
            role_details,
            countryLevelPrices,
            countryPriceRequestMaterials,
        ]
    );

    useEffect(() => {
        let storeMaterialsClone = cloneDeep(storeMaterials);

        if (selectedExceptionOptions.length) {
            const exceptionsMap = {};
            for (const exceptionOption of selectedExceptionOptions) {
                exceptionsMap[exceptionOption.value] = true;
            }

            storeMaterialsClone = storeMaterialsClone.filter((material) => {
                const { material_number, season_code } = material;
                const key = material_number + season_code;
                const materialAlertAndExceptions = alertsAndExceptions[key];
                const exceptions = [];

                if (materialAlertAndExceptions) {
                    for (const {
                        id,
                        entry_type,
                    } of materialAlertAndExceptions) {
                        if (exceptionsMap[id]) {
                            return true;
                        }

                        if (entry_type === 2) {
                            exceptions.push(id);
                        }
                    }
                }

                if (
                    Object.keys(exceptionsMap).includes("noExceptions") &&
                    exceptions?.length === 0
                ) {
                    return true;
                }

                return false;
            });
        }

        if (selectedAlertOptions.length) {
            const alertsMap = {};
            for (const alertOption of selectedAlertOptions) {
                alertsMap[alertOption.value] = true;
            }

            storeMaterialsClone = storeMaterialsClone.filter((material) => {
                const { material_number, season_code } = material;
                const key = material_number + season_code;
                const materialAlertAndExceptions = alertsAndExceptions[key];
                const alerts = [];

                if (materialAlertAndExceptions) {
                    for (const {
                        id,
                        entry_type,
                    } of materialAlertAndExceptions) {
                        if (entry_type === 1) {
                            alerts.push(id);
                        }
                        if (alertsMap[id]) {
                            return true;
                        }
                    }
                }

                return (
                    Object.keys(alertsMap).includes("noAlerts") &&
                    alerts?.length === 0
                );
            });
        }

        storeMaterialsClone.sort((mat1, mat2) => {
            const isMat1Accessible = checkMaterialSelectionValidity(
                role_details,
                allowed_regions,
                allowed_channel_hierarchies,
                mat1
            );
            const isMat2Accessible = checkMaterialSelectionValidity(
                role_details,
                allowed_regions,
                allowed_channel_hierarchies,
                mat2
            );

            if (!isMat1Accessible && isMat2Accessible) return 1;

            const { material_number: mn1, season_code: ms1 } = mat1;
            const { material_number: mn2, season_code: ms2 } = mat2;
            if (mn1 < mn2) return -1;
            if (mn1 > mn2) return 1;
            if (ms1 > ms2) return 1;
            return -1;
        });

        setMaterials(storeMaterialsClone);
    }, [
        storeMaterials,
        alertsAndExceptions,
        selectedAlertOptions,
        selectedExceptionOptions,
        role_details,
        allowed_regions,
        allowed_channel_hierarchies,
    ]);

    useEffect(() => {
        if (!editMaterialData) {
            setIsEditMaterialDataValid(false);

            return;
        }

        const { flag } = validateMaterial(editMaterialData);

        if (!flag) {
            setIsEditMaterialDataValid(false);
            return;
        }

        const editMaterialDataClone = cloneDeep(editMaterialData);

        editMaterialDataClone.msrp_price = parseFloat(
            editMaterialDataClone.msrp_price
        );

        if (isEqual(editMaterialDataClone, selectedMaterials[0]?.data)) {
            setIsEditMaterialDataValid(false);

            return;
        }

        setIsEditMaterialDataValid(true);
    }, [editMaterialData, selectedMaterials, dispatch]);

    useEffect(() => {
        const alerts = {};
        const exceptions = {};

        const [alertOptions, exceptionOptions] = alertAndExceptionOptions(
            alertsAndExceptions,
            alerts,
            exceptions
        );

        const alertOptionLabels = alertOptions.map((ao) => ao.label);
        const exceptionOptionLabels = exceptionOptions.map((ao) => ao.label);

        setSelectedAlertOptions((prev) =>
            prev.filter((e) => alertOptionLabels.includes(e.label))
        );
        setSelectedExceptionOptions((prev) =>
            prev.filter((e) => exceptionOptionLabels.includes(e.label))
        );

        setAlertOptions(alertOptions);
        setExceptionOptions(exceptionOptions);
    }, [alertsAndExceptions]);

    useEffect(() => {
        setMaterials((prevValue) => cloneDeep(prevValue));
    }, [countryLevelPrices, countryPriceRequestMaterials]);

    const onMaterialValueChange = useCallback(
        (key, newValue) => {
            const newEditMaterialData = cloneDeep(editMaterialData);

            newEditMaterialData[key] = newValue;

            if (!triggerUnsavedEdit?.includes("base")) {
                if (!triggerUnsavedEdit)
                    dispatch(setTriggerUnsavedEdit("base"));
                else
                    dispatch(
                        setTriggerUnsavedEdit(triggerUnsavedEdit + "base")
                    );
            }

            setEditMaterialData(newEditMaterialData);
        },
        [editMaterialData, triggerUnsavedEdit]
    );

    const headerCheckboxSelectionCallback = useCallback(() => {
        return (
            !materialEditMode &&
            materials.filter(isMaterialSelectable).length &&
            !basePricingAlertsConfigLoader &&
            !similarAndPfsMaterialsLoader
        );
    }, [
        materialEditMode,
        materials,
        isMaterialSelectable,
        basePricingAlertsConfigLoader,
        similarAndPfsMaterialsLoader,
    ]);

    const checkboxSelectionCallback = useCallback(
        () =>
            !materialEditMode &&
            !basePricingAlertsConfigLoader &&
            !similarAndPfsMaterialsLoader,
        [
            materialEditMode,
            basePricingAlertsConfigLoader,
            similarAndPfsMaterialsLoader,
        ]
    );

    const colDefs = useMemo(() => {
        const attributeColumnDefMapCopy = cloneDeep(attributeColumnDefMap);

        tableAttributes.forEach(({ value }) => {
            if (attributeColumnDefMapCopy[value]) {
                attributeColumnDefMapCopy[value].flag = false;
            }
        });

        const selectedTableAttributes = appliedTableSettings
            ? filterAndMapColumnChannelWise(
                  appliedTableSettings.view_data.columns,
                  selectedChannels
              ).filter((col) => col.isSelected)
            : [];

        selectedTableAttributes.forEach(({ value }) => {
            const col = attributeColumnDefMapCopy[value];
            if (col) {
                col.flag = true;
                if (col.type === "similarOrPfs") {
                    col.columnDef = {
                        ...col.columnDef,
                        headerComponent: similarAndPfsMaterialsLoader
                            ? () => (
                                  <>
                                      {col.columnDef.headerName}
                                      <RotateRightIcon
                                          className={`${styles["load-indicator"]}`}
                                          titleAccess="Loading Similar And Pfs Materials"
                                      />
                                  </>
                              )
                            : undefined,
                    };
                }
            }
        });

        const newColDefs = [];
        const defaultColumnDefsCopy = cloneDeep(defaultColumnDefs);

        defaultColumnDefsCopy[0].headerCheckboxSelection =
            headerCheckboxSelectionCallback;
        defaultColumnDefsCopy[0].checkboxSelection = checkboxSelectionCallback;

        const materialNumberCol = defaultColumnDefsCopy.find(
            (col) => col.field === "material_number"
        );
        materialNumberCol.cellRendererParams.page = page;

        newColDefs.push.apply(newColDefs, defaultColumnDefsCopy);

        for (const { value: field, pinned, label } of selectedTableAttributes) {
            const columnData = attributeColumnDefMapCopy[field];
            if (columnData) {
                const { flag, columnDef, multi, skip } = columnData;
                columnDef.headerName = label;
                if (
                    appliedTableSettings.view_data.columnWidth ===
                        tableSettingColumnWidthModes.CUSTOM &&
                    appliedTableSettings.view_data.widths[field] !== undefined
                ) {
                    columnDef.width =
                        appliedTableSettings.view_data.widths[field];
                }

                if (
                    userResizedColumns[
                        tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS
                    ][field] !== undefined
                ) {
                    columnDef.width =
                        userResizedColumns[
                            tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS
                        ][field];
                }

                columnDef.pinned = getColPinValue(pinned);
                columnDef.cellStyle =
                    getColPinValue(pinned) === "right"
                        ? {
                              ...rightPinCellStyle,
                              ...(columnDef.cellStyle || {}),
                          }
                        : { ...(columnDef.cellStyle || {}) };

                if (
                    [
                        "rtlPriceEffectiveDate",
                        "whslPriceEffectiveDate",
                        "euStdMsrpMercOverride",
                        "discount",
                        "storePrice",
                    ].includes(field)
                ) {
                    columnDef.cellRendererParams = {
                        custom: {
                            editMode: materialEditMode,
                            onChange: (newValue) =>
                                onMaterialValueChange(
                                    columnFieldObjKeyMap[field],
                                    newValue
                                ),
                            selectedNode: selectedMaterials[0],
                            editData: editMaterialData,
                            showAuditLog,
                            type:
                                field === "whslPriceEffectiveDate"
                                    ? "WHSL"
                                    : "MSRP",
                        },
                    };

                    columnDef.cellStyle = (props) => {
                        return materialEditMode &&
                            selectedMaterials[0] === props.node
                            ? { padding: "0px" }
                            : { display: "flex", alignItems: "center" };
                    };
                }

                if (["similarMaterial", "pfsWasMaterial"].includes(field)) {
                    columnDef.cellRendererParams = {
                        ...columnDef.cellRendererParams,
                        showAuditLog,
                        type:
                            field === "whslPriceEffectiveDate"
                                ? "WHSL"
                                : "MSRP",
                    };
                }

                if ("euStdWhslMercOverride" === field) {
                    columnDef.cellRendererParams = {
                        custom: {
                            showAuditLog,
                            type: "WHSL",
                        },
                    };
                    columnDef.cellStyle = {
                        display: "flex",
                        alignItems: "center",
                    };
                }

                if (skip) {
                    continue;
                }

                if (multi) {
                    const children = [];

                    if (flag) {
                        children.push(columnDef);
                    }

                    for (const pairField of multi.pairKeys) {
                        if (attributeColumnDefMapCopy[pairField]?.flag) {
                            children.push(
                                attributeColumnDefMapCopy[pairField].columnDef
                            );
                        }
                    }

                    if (children.length) {
                        newColDefs.push(emptyColumn);
                        newColDefs.push({ ...multi.columnDef, children });
                    }
                } else {
                    if (flag) {
                        newColDefs.push(columnDef);
                    }
                }
            }
        }

        // overriding percentage formatter only for margin columns
        try {
            newColDefs.forEach((column) => {
                if (
                    ["margin_dtc_perc", "margin_wholesale_perc"].includes(
                        column.field.toLowerCase()
                    ) &&
                    column?.filterParams
                ) {
                    column.filterParams = cloneDeep(column.filterParams);
                    column.filterParams.filters[1].filterParams.valueFormatter =
                        percentageFormatter;
                }
            });
        } catch (e) {
            console.error(e);
        }

        return newColDefs;
    }, [
        materialEditMode,
        selectedMaterials,
        page,
        editMaterialData,
        appliedTableSettings,
        userResizedColumns,
        similarAndPfsMaterialsLoader,
        showAuditLog,
        headerCheckboxSelectionCallback,
        checkboxSelectionCallback,
        onMaterialValueChange,
    ]);

    const isMaterialSelectableForChange = (material) =>
        ![1, 2, 5, 6].includes(material.data.approval_status) &&
        !(
            material.data.approval_status > 3 &&
            !(role_details[SUPER_USER] || role_details[PRICING_TEAM])
        );

    const onGeneratePricelistClick = () => {
        const filteredOnApprovalCol = selectedMaterials.filter((sm) =>
            isMaterialSelectableForChange(sm)
        );
        if (filteredOnApprovalCol.length === 0) {
            dispatch(
                toastError(
                    "All the selected materials are pending approval or approved"
                )
            );
            return;
        }

        const errors = [];

        const filteredSelectedMaterials = filteredOnApprovalCol.filter(
            (material) =>
                isMaterialWithoutMandatoryException(
                    material.data,
                    alertsAndExceptions
                )
        );

        const mandatoryExceptionFound =
            filteredSelectedMaterials.length !== filteredOnApprovalCol.length;

        if (mandatoryExceptionFound) {
            errors.push("mandatory exceptions");
        }

        const materialsWithValidDates = filteredSelectedMaterials.filter(
            ({ data: { msrp_effective_date, wholesale_effective_date } }) =>
                moment(msrp_effective_date).isValid() &&
                moment(wholesale_effective_date).isValid()
        );

        const invalidEffectiveDateFound =
            filteredSelectedMaterials.length !== materialsWithValidDates.length;

        if (invalidEffectiveDateFound) {
            errors.push("effective dates missing");
        }

        const materialsWithValidPriceRule = materialsWithValidDates.filter(
            ({ data: { price_rule_name } }) =>
                price_rule_name?.length && price_rule_name.length > 0
        );

        const invalidPriceRuleFound =
            materialsWithValidDates.length !==
            materialsWithValidPriceRule.length;

        if (invalidPriceRuleFound) {
            errors.push("invalid price rule");
        }

        const materialsWithValidPrices = materialsWithValidPriceRule.filter(
            ({ data: { msrp_price, wholesale_price, store_price } }) =>
                validatePrice(msrp_price) &&
                validatePrice(
                    selectedChannels[0].label === "OUTLET"
                        ? store_price
                        : wholesale_price
                )
        );

        const invalidPricesFound =
            materialsWithValidPriceRule.length !==
            materialsWithValidPrices.length;

        if (invalidPricesFound) {
            errors.push("invalid prices");
        }

        const materialsWithAppliedPriceRule = materialsWithValidPrices.filter(
            ({ data: { price_rule_status } }) => price_rule_status === 1
        );

        const revisionPriceRuleMaterialsFound =
            materialsWithValidPrices.length !==
            materialsWithAppliedPriceRule.length;

        if (revisionPriceRuleMaterialsFound) {
            errors.push("no price rule applied");
        }

        setMaterialsForPriceGeneration(materialsWithAppliedPriceRule);

        const errorStr = errors.join(", ");

        const materialsToBeReappliedPriceRules =
            materialsWithAppliedPriceRule.filter(
                ({ data: { is_price_rule_applied } }) =>
                    is_price_rule_applied === 1
            );

        if (materialsToBeReappliedPriceRules.length) {
            setConfirmGenerateCountryLevelPriceModalText(
                "Some/All selected materials have the 'Re-apply price rule' action active, it is advised to re-apply the price rule before generating regional prices. Do you still want to proceed?"
            );

            setConfirmGenerateCountryLevelPriceModalOpen(true);
            return;
        }

        if (materialsWithAppliedPriceRule.length) {
            if (
                mandatoryExceptionFound ||
                invalidEffectiveDateFound ||
                invalidPriceRuleFound ||
                invalidPricesFound ||
                revisionPriceRuleMaterialsFound
            ) {
                setConfirmGenerateCountryLevelPriceModalText(`Some materials have ${errorStr}. Do you
                want to proceed with the rest of the ${materialsWithAppliedPriceRule.length} material(s)?`);

                setConfirmGenerateCountryLevelPriceModalOpen(true);
                return;
            }
            onConfirmGenerateCountryLevelPriceProceed(
                materialsWithAppliedPriceRule
            );
            return;
        }
        dispatch(toastError(`Selected material(s) have ${errorStr}`));
    };

    const onPriceRuleRefreshClick = () => {
        const filteredOnApprovalCol = selectedMaterials.filter((sm) =>
            isMaterialSelectableForChange(sm)
        );
        if (filteredOnApprovalCol.length === 0) {
            dispatch(
                toastError(
                    "All the selected materials are pending approval or approved"
                )
            );
            return;
        }

        const isEffectiveDatesEmpty = filteredOnApprovalCol.some(
            (materials) =>
                !materials.data.wholesale_effective_date ||
                !materials.data.msrp_effective_date
        );

        if (isEffectiveDatesEmpty) {
            dispatch(
                toastError(
                    "All the selected materials should have effective dates."
                )
            );
            return;
        }

        let filteredSelectedMaterials = filteredOnApprovalCol.filter(
            (material) =>
                isMaterialWithoutMandatoryException(
                    material.data,
                    alertsAndExceptions
                )
        );
        if (!filteredSelectedMaterials.length) {
            dispatch(
                toastError("Selected material(s) have mandatory exceptions")
            );

            return;
        }

        filteredSelectedMaterials = filteredSelectedMaterials.filter(
            (material) => material.data.is_price_rule_applied === 1
        );
        if (!filteredSelectedMaterials.length) {
            dispatch(
                toastError(
                    "All the materials selected have no change in price rule"
                )
            );

            return;
        }

        // price rule should be present and not in revision
        filteredSelectedMaterials = filteredSelectedMaterials.filter(
            (material) =>
                material.data.price_rule_status !== 2 &&
                material.data.price_rule_name
        );
        if (!filteredSelectedMaterials.length) {
            dispatch(
                toastError(
                    "All the materials selected have either no change in price rule or the price rule is missing or not in applied status"
                )
            );

            return;
        }

        if (filteredSelectedMaterials.length !== selectedMaterials.length) {
            dispatch(
                toastSuccessMessage(
                    `Price rule refresh requested for ${filteredSelectedMaterials.length} of ${selectedMaterials.length} materials`
                )
            );
        }

        const price_file_material_ids = filteredSelectedMaterials.map(
            (material) => material.data.id
        );

        const data = {
            method_type: "POST",
            price_file_material_ids,
            market_id: selectedMarkets[0].value,
            region: selectedRegions[0].label,
            channel: selectedChannels[0].label,
            tab: {
                id: selectedTab,
                data:
                    selectedTab === 2
                        ? {
                              page: currentPage,
                              data:
                                  currentPage === 1
                                      ? selectedFilters
                                      : {
                                            selectedAttributeOptions,
                                            selectedSeparatorOptions,
                                            pasteAreaValue,
                                            validValues,
                                            invalidValues,
                                        },
                          }
                        : selectedPlanGroup,
            },
        };

        dispatch(refreshPriceRule(data));
        gridRef?.current?.api?.deselectAll();
    };

    const onMaterialBulkEditClick = () => {
        if (!permissions.edit) {
            return;
        }

        setRtlDateChangeValue(moment());
        setWhslDateChangeValue(moment());
        setRtlDateChangeMode("reset");
        setWhslDateChangeMode("reset");
        setIsDateChangeEnabled(false);
        setIsPriceChangeEnabled(false);
        setIsDiscountChangeEnabled(false);
        setPriceChangeValue(1);
        setDiscountChangeValue(1);
        setBulkEditModalOpen(true);
    };

    const onEditSpecialAttributeClick = () => {
        setNewSpecialAttribute("");
        setSpecialAttributeModalOpen(true);
    };
    const onEditSpecialAttributeClose = () => {
        setSpecialAttributeModalOpen(false);
    };
    const onEditSpecialAttributeSubmit = () => {
        const materialFetchData = getMaterialFetchData(
            selectedFilters,
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId
        );

        const filteredOnApprovalCol = selectedMaterials.filter((sm) =>
            isMaterialSelectableForChange(sm)
        );
        if (filteredOnApprovalCol.length === 0) {
            dispatch(
                toastError(
                    "All the selected materials are pending approval or approved"
                )
            );
            return;
        }

        const payload = {
            market_id: filteredOnApprovalCol[0].data.market_id,
            region: filteredOnApprovalCol[0].data.region,
            channel: filteredOnApprovalCol[0].data.channel,
            update_value: newSpecialAttribute,
            price_file_material_ids: filteredOnApprovalCol.map(
                (e) => e.data.id
            ),
        };

        editSpecialAttribute(payload)
            .then(() => {
                dispatch(
                    getPriceFileMaterialsAndCountryLevelPrices(
                        page,
                        materialFetchData
                    )
                );
                dispatch(
                    requestComplete("Special attribute updated successfully")
                );
                gridRef?.current?.api?.deselectAll();
            })
            .then(() => {
                onEditSpecialAttributeClose();
            })
            .catch((err) => {
                dispatch(toastError(err.data.message));
            });
    };

    const onResetDatesClick = () => {
        if (!permissions.edit) {
            return;
        }

        setDateChangeMode("reset");

        setConfirmDateChangeModalOpen(true);
    };

    const onResetMaterials = (type) => {
        setResetAnchorEl(null);

        const payload = {
            region: selectedRegions[0].label,
            channel: selectedChannels[0].label,
            market_id: selectedMarkets[0].value,
            pfm_id: selectedMaterials.map((sm) => sm.data.id),
            fetch_type: "reset",
        };

        dispatch(requestCompStart("similarAndPfsMaterials"));

        getSimilarOrPfsAPI(payload, type, "filterByAttributes")
            .then((res) => {
                const temp = cloneDeep(materials);

                if (type === "similar") {
                    res.data?.data?.map((sr) => {
                        const idx = temp.findIndex(
                            (m) => m.source_id === sr.id
                        );
                        if (idx !== -1) {
                            temp[idx].similar_material = sr.material_number;
                            temp[idx].similar_material_image_url = sr.image_url;
                            temp[idx].similar_material_season = sr.season;
                            temp[idx].similar_material_market_name =
                                sr.market_name;
                            temp[idx].similar_material_channel = sr.channel;
                        }
                    });
                } else {
                    res.data?.data?.map((pr) => {
                        const idx = temp.findIndex(
                            (m) => m.source_id === pr.id
                        );
                        if (idx !== -1) {
                            temp[idx].pfs_was_material = pr.material_number;
                            temp[idx].pfs_was_image_url = pr.image_url;
                            temp[idx].pfs_was_material_season = pr.season;
                            temp[idx].pfs_was_market_name = pr.market_name;
                            temp[idx].pfs_was_indicative = pr.msrp_price;
                            temp[idx].msrp_price = pr.msrp_price;
                        }
                    });
                }

                dispatch(
                    setReduxMaterials({
                        page: "filterByAttributes",
                        data: temp,
                    })
                );

                gridRef?.current?.api?.deselectAll();
                dispatch(requestCompComplete("similarAndPfsMaterials"));
            })
            .catch((err) => {
                dispatch(requestCompFail("similarAndPfsMaterials"));
            });
    };

    const onMaterialUntagClick = () => {
        const filteredOnApprovalCol = selectedMaterials.filter((sm) =>
            isMaterialSelectableForChange(sm)
        );
        if (filteredOnApprovalCol.length === 0) {
            dispatch(
                toastError(
                    "All the selected materials are pending approval or approved"
                )
            );
            return;
        }

        if (!permissions.delete) {
            return;
        }

        const materialFetchData = getMaterialFetchData(
            selectedFilters,
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId
        );

        const data = {
            method_type: "DELETE",
            price_file_material_ids: filteredOnApprovalCol.map((material) =>
                parseInt(material.data.id)
            ),
        };

        dispatch(untagMaterials({ page, data, materialFetchData }))
            .then(() => {
                setShowUntagMaterialModal(false);
            })
            .catch(() => {
                setShowUntagMaterialModal(false);
            });

        setSelectedMaterials([]);
        gridRef.current.api.deselectAll();
    };

    const onMaterialEditClick = () => {
        if (!permissions.edit) {
            return;
        }

        setMaterialEditMode(true);

        dispatch(
            setTriggerUnsavedEdit(
                !triggerUnsavedEdit
                    ? "base"
                    : triggerUnsavedEdit.includes("base")
                    ? triggerUnsavedEdit
                    : triggerUnsavedEdit + "base"
            )
        );

        gridRef.current.api.setFocusedCell(
            selectedMaterials[0].rowIndex,
            "msrp_effective_date"
        );

        setEditMaterialData(selectedMaterials[0].data);
    };

    const onMaterialSaveClick = () => {
        if (!permissions.edit) {
            return;
        }

        const {
            price_file_id,
            material_number,
            season_code,
            msrp_effective_date,
            wholesale_effective_date,
            msrp_price,
            gfe_material_status,
            itp_flag_dtc,
            itp_flag_whsl,
            dtc_projection_units,
            wholesale_projection_units,
            std_cost,
            tfg_manual,
            fty_cost_manual,
            style_number,
            item_number,
            dtc_actual_units,
            wholesale_actual_units,
            discount,
        } = editMaterialData;

        if (discount < 0 || discount >= 100) {
            return dispatch(
                toastError(
                    "Discount should be in range of 0 to 99%. Please enter a valid discount."
                )
            );
        }

        let tempDiscount = Number(discount);

        if (
            discount === null ||
            discount === undefined ||
            discount === "" ||
            (discount + "").trim() === "-"
        ) {
            tempDiscount = null;
        }

        const data = {
            price_file_id,
            material: material_number,
            season: season_code,
            msrp_effective_date: moment(msrp_effective_date ?? "").isValid()
                ? moment(msrp_effective_date ?? "").format("YYYY-MM-DD")
                : null,
            wholesale_effective_date: moment(
                wholesale_effective_date ?? ""
            ).isValid()
                ? moment(wholesale_effective_date ?? "").format("YYYY-MM-DD")
                : null,
            msrp_price: parseFloat(msrp_price),
            channel: selectedChannels[0].label,
            region: selectedRegions[0].label,
            market_id: selectedMarkets[0].value,
            gfe_material_status,
            itp_flag_dtc,
            itp_flag_whsl,
            dtc_projection_units,
            wholesale_projection_units,
            std_cost,
            tfg_manual,
            fty_cost_manual,
            dtc_actual_units,
            wholesale_actual_units,
            discount: tempDiscount,
        };

        const miscData = {
            style_number,
            item_number,
        };

        const materialFetchData = getMaterialFetchData(
            selectedFilters,
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId
        );

        dispatch(
            setEditedMaterial({ page, data: [cloneDeep(editMaterialData)] })
        );

        dispatch(
            editMaterial({ page, data, miscData, materialFetchData })
        ).then(() => {
            setSelectedMaterials([]);
            gridRef.current.api.deselectAll();
            dispatch(
                setTriggerUnsavedEdit(
                    triggerUnsavedEdit === "base"
                        ? null
                        : triggerUnsavedEdit?.replace("base", "")
                )
            );
        });

        setMaterialEditMode(false);
        setEditMaterialData(null);
    };

    const onMaterialDiscardClick = () => {
        setMaterialEditMode(false);
        setEditMaterialData(null);
        dispatch(
            setTriggerUnsavedEdit(
                triggerUnsavedEdit === "base"
                    ? null
                    : triggerUnsavedEdit?.replace("base", "")
            )
        );
    };

    const processCellForClipboard = useCallback((params) => {
        const col = params.column.getColId();
        if (col === "is_price_rule_applied")
            return actionRequired[params.value]?.text || "-";
        else if (col === "approval_status")
            return params.value || params.value === 0
                ? APPROVAL_STATUS[params.value]
                : "";

        return params.value;
    }, []);

    const onRefreshClick = () => {
        const materialFetchData = getMaterialFetchData(
            selectedFilters,
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId
        );

        dispatch(
            getPriceFileMaterialsAndCountryLevelPrices(page, materialFetchData)
        );
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetClick = (event) => {
        setResetAnchorEl(event.currentTarget);
    };

    const onSettingsClick = () => {
        setShowTableSettings(true);
    };

    const getFormattedCellValue = (
        header,
        val,
        params,
        alertsAndExceptions
    ) => {
        if (
            ["MARKET", "REGION", "CHANNEL", "ALERTS", "EXCEPTIONS"].includes(
                header
            )
        ) {
            const { material_number, season_code } = params.node.data;
            const key = material_number + season_code;
            const materialAlertAndExceptions = alertsAndExceptions?.[key];

            const getDescription = (passedEntryType) => {
                let result = "";
                if (materialAlertAndExceptions) {
                    for (const {
                        entry_type,
                        description,
                    } of materialAlertAndExceptions) {
                        if (entry_type === passedEntryType) {
                            result = `${description}${
                                result === "" ? "" : ", " + result
                            }`;
                        }
                    }
                }
                return result;
            };

            switch (header) {
                case "MARKET":
                    return selectedMarkets[0].label;
                case "REGION":
                    return selectedRegions[0].label;
                case "CHANNEL":
                    return selectedChannels[0].label;
                case "ALERTS":
                    return getDescription(1);
                case "EXCEPTIONS":
                    return getDescription(2);
                default:
            }
        }

        if (!val && !Number.isFinite(val)) return "";
        switch ((header || "").toUpperCase()) {
            case "APPROVAL STATUS":
                return val || val === 0 ? APPROVAL_STATUS[val] : "";
            case "FACTORY COST MANUAL (USD)":
            case "US MSRP":
            case "US WHSL":
                return val === "-" ? "" : "$" + val.toFixed(2);
            case "REGIONAL STANDARD COST":
            case "REGIONAL TFG MANUAL (EUR)":
            case "EURO STANDARD MSRP (EUR)":
            case "EURO STANDARD WHSL (EUR)":
            case "DTC SALES":
            case "WHSL SALES":
            case "DTC MARGIN":
            case "WHSL MARGIN":
                return val === "-" ? "" : "€" + val.toFixed(2);
            case "DTC MARGIN %":
            case "WHSL MARGIN %":
                return val === "-" ? "" : val.toFixed(2) + "%";
            case "ACTION REQUIRED":
                return actionRequired[val]?.text || "-";
            default:
                return val;
        }
    };

    const onDownloadTableDataClick = () => {
        if (!permissions.download) {
            return;
        }

        const cols = gridRef.current?.api
            ?.getColumnDefs()
            .filter((e) =>
                e.headerName?.length && isHistoricalData
                    ? e.headerName !== "ALERTS" && e.headerName !== "EXCEPTIONS"
                    : 1
            )
            .map((e) => e.field);

        gridRef.current.api.exportDataAsExcel({
            fileName: `Base Prices ${new Date().toLocaleString()}`,
            processCellCallback(params) {
                const val = params.value;
                const header = params.column.colDef.headerName;
                return getFormattedCellValue(
                    header,
                    val,
                    params,
                    alertsAndExceptions
                );
            },
            columnKeys: cols,
            onlySelected: selectedMaterials.length,
        });

        handleClose();
    };

    const onDownloadAuditLogClick = useCallback(() => {
        const mainFilters = {
            selectedMarkets,
            selectedRegions,
            selectedChannels,
        };
        auditLogDownloader(
            downloadAuditLogData,
            selectedMaterials,
            materials,
            mainFilters,
            gridRef,
            handleClose,
            dispatch
        );
    }, [
        materials,
        selectedMaterials,
        selectedMarkets,
        selectedRegions,
        selectedChannels,
        dispatch,
    ]);

    const onMaterialSelect = useCallback(
        (event) => {
            const filteredMaterials = gridRef.current.api.getSelectedNodes();

            setSelectedMaterials(filteredMaterials);

            onTableSelectChange(event);

            if (filteredMaterials.length !== editedMaterialsFromStore.length) {
                dispatch(setEditedMaterial({ page, data: [] }));
            }
        },
        [page, editedMaterialsFromStore, onTableSelectChange, dispatch]
    );

    useEffect(() => {
        if (currentTab !== "country") {
            let colNamesWithWidth = [];

            if (
                appliedTableSettings?.view_data.columnWidth ===
                tableSettingColumnWidthModes.CUSTOM
            ) {
                const colKeysWithWidth = Object.keys(
                    appliedTableSettings.view_data.widths
                );

                colNamesWithWidth = appliedTableSettings.view_data.columns
                    .filter((col) => colKeysWithWidth.includes(col.id))
                    .map((col) => col.name.toLowerCase());
            }

            if (appliedTableSettings) {
                const userResizedColKeysWithWidth = Object.keys(
                    userResizedColumns[
                        tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS
                    ]
                );

                const userResizedColNamesWithWidth =
                    appliedTableSettings.view_data.columns
                        .filter((col) =>
                            userResizedColKeysWithWidth.includes(col.id)
                        )
                        .map((col) => col.name.toLowerCase());

                for (const colName of userResizedColNamesWithWidth) {
                    if (!colNamesWithWidth.includes(colName)) {
                        colNamesWithWidth.push(colName);
                    }
                }
            }

            gridRef?.current?.columnApi?.autoSizeColumns(
                gridRef.current.columnApi
                    .getColumns()
                    .slice(8)
                    .filter(
                        (col) =>
                            !colNamesWithWidth.includes(
                                col.userProvidedColDef.headerName.toLowerCase()
                            )
                    )
                    .map((col) => col.getId())
            );
        }
    }, [currentTab, userResizedColumns, appliedTableSettings]);

    const onTableDataChange = useCallback(
        (event) => {
            let colNamesWithWidth = [];

            if (
                appliedTableSettings?.view_data.columnWidth ===
                tableSettingColumnWidthModes.CUSTOM
            ) {
                const colKeysWithWidth = Object.keys(
                    appliedTableSettings.view_data.widths
                );

                colNamesWithWidth = appliedTableSettings.view_data.columns
                    .filter((col) => colKeysWithWidth.includes(col.id))
                    .map((col) => col.name.toLowerCase());
            }

            if (appliedTableSettings) {
                const userResizedColKeysWithWidth = Object.keys(
                    userResizedColumns[
                        tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS
                    ]
                );

                const userResizedColNamesWithWidth =
                    appliedTableSettings.view_data.columns
                        .filter((col) =>
                            userResizedColKeysWithWidth.includes(col.id)
                        )
                        .map((col) => col.name.toLowerCase());

                for (const colName of userResizedColNamesWithWidth) {
                    if (!colNamesWithWidth.includes(colName)) {
                        colNamesWithWidth.push(colName);
                    }
                }
            }

            event.columnApi.autoSizeColumns(
                event.columnApi
                    .getColumns()
                    .slice(8)
                    .filter(
                        (col) =>
                            !colNamesWithWidth.includes(
                                col.userProvidedColDef.headerName.toLowerCase()
                            )
                    )
                    .map((col) => col.getId())
            );

            onTableModelUpdate(event);
        },
        [appliedTableSettings, userResizedColumns, onTableModelUpdate]
    );

    const isRowSelectable = useCallback(
        ({ data }) => isMaterialSelectable(data) && !isHistoricalData,
        [isMaterialSelectable, isHistoricalData]
    );

    const onColumnResize = (event) => {
        if (
            !event ||
            !event.column ||
            !event.finished ||
            event.source !== "uiColumnResized"
        ) {
            return;
        }

        const colHeaderName =
            event.column.userProvidedColDef.headerName.toLowerCase();
        const colResized = tableAttributes.find(
            (attribute) => attribute.label.toLowerCase() === colHeaderName
        );

        if (!colResized) {
            return;
        }

        const colId = colResized.value;
        const newUserResizedColumns = cloneDeep(
            userResizedColumns[tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS]
        );

        newUserResizedColumns[colId] = event.column.actualWidth;

        dispatch(
            setUserResizedColumns({
                tabId: tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS,
                newData: newUserResizedColumns,
            })
        );
    };

    const onConfirmDateChangeModalClose = () => {
        setConfirmDateChangeModalOpen(false);
    };

    const onConfirmDateChangeSubmit = () => {
        const materialFetchData = getMaterialFetchData(
            selectedFilters,
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId
        );

        const filteredOnApprovalCol = selectedMaterials.filter((sm) =>
            isMaterialSelectableForChange(sm)
        );
        if (filteredOnApprovalCol.length === 0) {
            dispatch(
                toastError(
                    "All the selected materials are pending approval or approved"
                )
            );
            return;
        }

        if (dateChangeMode === "reset") {
            const data = {};

            data.rtl_price_effective_date = true;
            data.whsl_price_effective_date = true;

            data.update_list = filteredOnApprovalCol.map((material) => {
                const {
                    channel,
                    region,
                    market_id,
                    material_number,
                    season_code,
                    id,
                } = material.data;

                return {
                    channel,
                    region,
                    market_id,
                    material: material_number,
                    season: season_code,
                    price_file_material_id: id,
                };
            });

            dispatch(
                setEditedMaterial({
                    page,
                    data: cloneDeep(selectedMaterials.map((node) => node.data)),
                })
            );

            dispatch(
                resetMaterialDates({ page, data, materialFetchData })
            ).catch((err) => {
                dispatch(requestFail(err.message));
            });
        } else if (dateChangeMode === "bulkEdit") {
            const promises = [];

            if (
                (rtlDateChangeMode === "reset" ||
                    whslDateChangeMode === "reset") &&
                isDateChangeEnabled
            ) {
                const data = {};

                data.rtl_price_effective_date = rtlDateChangeMode === "reset";
                data.whsl_price_effective_date = whslDateChangeMode === "reset";
                data.price = false;

                data.update_list = filteredOnApprovalCol.map((material) => {
                    const {
                        channel,
                        region,
                        market_id,
                        material_number,
                        season_code,
                        id,
                    } = material.data;

                    return {
                        channel,
                        region,
                        market_id,
                        material: material_number,
                        season: season_code,
                        price_file_material_id: id,
                    };
                });

                promises.push(
                    dispatch(
                        resetMaterialDates({
                            page,
                            data,
                            materialFetchData,
                            fetchMaterialsFlag: false,
                        })
                    )
                );
            }

            if (
                rtlDateChangeMode === "edit" ||
                whslDateChangeMode === "edit" ||
                isPriceChangeEnabled ||
                isDiscountChangeEnabled
            ) {
                const data = {};

                data.rtl_price_effective_date =
                    rtlDateChangeMode === "edit" && isDateChangeEnabled;
                data.whsl_price_effective_date =
                    whslDateChangeMode === "edit" && isDateChangeEnabled;
                data.price = isPriceChangeEnabled;
                data.discount = isDiscountChangeEnabled;

                data.update_list = filteredOnApprovalCol.map((material) => {
                    const {
                        channel,
                        region,
                        market_id,
                        material_number,
                        season_code,
                        price_rule_id,
                        id,
                    } = material.data;

                    let tempDiscount = Number(discountChangeValue);

                    if (
                        !isDiscountChangeEnabled ||
                        discountChangeValue === null ||
                        discountChangeValue === undefined ||
                        discountChangeValue === "" ||
                        (discountChangeValue + "").trim() === "-"
                    ) {
                        tempDiscount = null;
                    }

                    return {
                        channel,
                        region,
                        market_id,
                        material: material_number,
                        season: season_code,
                        msrp_price: isPriceChangeEnabled
                            ? priceChangeValue
                            : undefined,
                        price_rule_id: isPriceChangeEnabled
                            ? price_rule_id
                            : undefined,
                        msrp_effective_date:
                            rtlDateChangeMode === "edit" && isDateChangeEnabled
                                ? rtlDateChangeValue.format("YYYY-MM-DD")
                                : "",
                        wholesale_effective_date:
                            whslDateChangeMode === "edit" && isDateChangeEnabled
                                ? whslDateChangeValue.format("YYYY-MM-DD")
                                : "",
                        price_file_material_id: id,
                        discount: tempDiscount,
                    };
                });

                promises.push(
                    dispatch(
                        bulkEditMaterial({
                            page,
                            data,
                            materialFetchData,
                            fetchMaterialsFlag: false,
                        })
                    )
                );
            }

            dispatch(
                setEditedMaterial({
                    page,
                    data: cloneDeep(selectedMaterials.map((node) => node.data)),
                })
            );

            Promise.allSettled(promises).then((res) => {
                if (!res.find((e) => e.status !== "fulfilled"))
                    dispatch(
                        toastSuccessMessage(
                            "The selected prices and/or effective dates have been successfully updated/reset"
                        )
                    );
                else {
                    const successMessage = res
                        .filter((e) => e.status === "fulfilled")
                        .map((e) => e.value)
                        .join();
                    dispatch(
                        requestFail(
                            (successMessage ? successMessage + " But " : "") +
                                res
                                    .filter((e) => e.status === "rejected")
                                    .map((e) => e.reason.message)
                                    .join()
                        )
                    );
                    dispatch(disableLoader());
                }
                dispatch(
                    getPriceFileMaterialsAndCountryLevelPrices(
                        page,
                        materialFetchData
                    )
                );
            });
        }

        setConfirmDateChangeModalOpen(false);

        setSelectedMaterials([]);
        gridRef.current.api.deselectAll();
    };

    const onConfirmDateChangeCancel = () => {
        setConfirmDateChangeModalOpen(false);
    };

    const onBulkEditModalClose = () => {
        setBulkEditModalOpen(false);
        gridRef?.current?.api?.deselectAll();
    };

    const onRtlDateChangeMode = (event) => {
        setRtlDateChangeMode(event.target.value);
    };

    const onRtlDateChange = (newValue) => {
        setRtlDateChangeValue(newValue);
    };

    const onWhslDateChangeMode = (event) => {
        setWhslDateChangeMode(event.target.value);
    };

    const onWhslDateChange = (newValue) => {
        setWhslDateChangeValue(newValue);
    };

    const onBulkEditSubmit = () => {
        let errors = [];

        if (
            rtlDateChangeMode === "edit" &&
            rtlDateChangeValue.format("YYYY-MM-DD") === "Invalid date"
        ) {
            errors.push("MSRP Price Effective Date");
        }

        if (
            whslDateChangeMode === "edit" &&
            whslDateChangeValue.format("YYYY-MM-DD") === "Invalid date"
        ) {
            errors.push("WHSL Price Effective Date");
        }

        if (
            selectedChannels?.[0]?.label === "OUTLET" &&
            (Number(discountChangeValue) < 0 ||
                Number(discountChangeValue) >= 100)
        ) {
            errors.push("Discount should be in range of 0 to 99%.");
        }

        if (isPriceChangeEnabled && priceChangeValue <= 0) {
            errors.push("Price can't be empty or less then 1");
        }

        if (errors.length) {
            const msg = `${errors.join(", ")} ${
                errors.length === 1 ? "is" : "are"
            } invalid. Please select a valid date.`;

            dispatch(toastError(msg));

            return;
        }

        setDateChangeMode("bulkEdit");

        setBulkEditModalOpen(false);
        setConfirmDateChangeModalOpen(true);
    };

    const onBulkEditModalCancel = () => {
        setBulkEditModalOpen(false);
    };

    const onConfirmGenerateCountryLevelPriceModalClose = () => {
        setConfirmGenerateCountryLevelPriceModalOpen(false);
    };

    const onConfirmGenerateCountryLevelPriceProceed = (materials) => {
        const price_file_material_ids = materials.map(
            (material) => material.data.id
        );

        const data = {
            method_type: "POST",
            price_file_material_ids,
            market_id: selectedMarkets[0].value,
            region: selectedRegions[0].label,
            channel: selectedChannels[0].label,
            tab: {
                id: selectedTab,
                data:
                    selectedTab === 2
                        ? {
                              page: currentPage,
                              data:
                                  currentPage === 1
                                      ? selectedFilters
                                      : {
                                            selectedAttributeOptions,
                                            selectedSeparatorOptions,
                                            pasteAreaValue,
                                            validValues,
                                            invalidValues,
                                        },
                          }
                        : selectedPlanGroup,
            },
        };

        const materialFetchData = getMaterialFetchData(
            selectedFilters,
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            priceFileId
        );

        dispatch(generateCountryLevelPrice(data, page, materialFetchData));

        setSelectedMaterials([]);
        gridRef.current.api.deselectAll();

        setConfirmGenerateCountryLevelPriceModalOpen(false);
    };

    const onConfirmGenerateCountryLevelPriceCancel = () => {
        setConfirmGenerateCountryLevelPriceModalOpen(false);
    };

    const onTableSettingsApply = (tabId, view) => {
        setAppliedTableSettings(view);
        setShowTableSettings(false);

        dispatch(
            setUserResizedColumns({
                tabId,
                newData: {},
            })
        );
    };

    const onTableSettingsClose = () => {
        setShowTableSettings(false);
    };

    useEffect(() => {
        gridRef.current?.api?.resetRowHeights();
    }, [appliedTableSettings]);

    useEffect(() => {
        updateClearAllFilterStatus();
    }, [selectedExceptionOptions, selectedAlertOptions]);

    const onGridReady = () => {
        gridRef.current.api.addEventListener("filterChanged", () => {
            updateClearAllFilterStatus();
        });
    };

    const updateClearAllFilterStatus = () => {
        setIsAnyFilterApplied(
            gridRef?.current?.api?.isAnyFilterPresent() +
                selectedExceptionOptions.length +
                selectedAlertOptions.length
        );
    };

    const onDiscountUploadClick = () => {
        setShowDiscountModal(!showDiscountModel);
    };

    const CellRendererForInnerTable = ({ rowIndex, data, value }) => {
        const {
            general: { innerTableToggleObject },
        } = useSelector((state) => state.workbench);
        const dispatch = useDispatch();

        const isSimilar =
            innerTableToggleObject.toggleCellType == "similarMaterial";

        const list =
            innerTableMemomisedData?.[data.source_material]?.[
                innerTableToggleObject?.toggleCellType
            ] || [];

        const onUpdateClick = () => {
            if (rowIndex == 0) {
                // TODO: for global search
                return;
            }

            rowExpandeEvent({
                rowIndex: innerTableToggleObject.rowIndex,
                isOpen: false,
            });

            const parentMaterial = materials[innerTableToggleObject.rowIndex];
            dispatch(
                updateSimilarPFSMaterials({
                    source_pfm_id: parentMaterial.id,
                    material_pfm_id: data.id,
                    search_type: isSimilar ? "similar" : "pfs",
                })
            )
                .then((res) => {
                    // console.log(res);
                    const responsMaterail = res[0];

                    if (isSimilar) {
                        parentMaterial.similar_material_channel =
                            responsMaterail.channel;
                        parentMaterial.similar_material_market_id =
                            responsMaterail.market_id;
                        parentMaterial.similar_material_market_name =
                            responsMaterail.market_name;
                        parentMaterial.similar_material =
                            responsMaterail.material;
                        parentMaterial.similar_material_season =
                            responsMaterail.season;
                    } else {
                        parentMaterial.pfs_was_material_channel =
                            responsMaterail.channel;
                        parentMaterial.pfs_was_material_market_id =
                            responsMaterail.market_id;
                        parentMaterial.pfs_was_market_name =
                            responsMaterail.market_name;
                        parentMaterial.pfs_was_material =
                            responsMaterail.material;
                        parentMaterial.pfs_was_indicative =
                            responsMaterail.pfs_was_indicative;
                        parentMaterial.msrp_price = responsMaterail.msrp_price;
                        parentMaterial.pfs_was_material_season =
                            responsMaterail.season;
                    }

                    list.forEach((item, index) => {
                        item.current_selection = rowIndex == index;
                    });
                    setMaterials([...materials]);
                })
                .catch((err) => console.error(err))
                .finally(() => {
                    setTimeout(() => {
                        rowExpandeEvent({
                            rowIndex: innerTableToggleObject.rowIndex,
                            isOpen: true,
                        });
                    }, 2000);
                });
        };

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <div className="pfs-inner-table-replace-button">
                    <CachedIcon onClick={onUpdateClick} />
                </div>
                <div>{value}</div>
                <div
                    style={{
                        background:
                            props.rowIndex % 2 == 0 ? "#DA1E28" : "#DA1E2800",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                    }}
                ></div>
            </div>
        );
    };

    const innerTableFormatComponent = (props) => {
        const { colDef, value, data } = props;
        // console.log(props);
        const list =
            innerTableMemomisedData?.[data.material_number]?.[
                innerTableToggleObject.toggleCellType
            ] || [];
        const isValueDifferent = list.length
            ? list[0][colDef.field] !== value &&
              !colDef.field.toLowerCase().includes("market") &&
              !colDef.field.toLowerCase().includes("season")
            : false;

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div>{value || "-"}</div>
                {isValueDifferent && (
                    <div
                        style={{
                            width: 10,
                            height: 10,
                            marginLeft: 10,
                            backgroundColor: "red",
                            borderRadius: "50%",
                        }}
                    ></div>
                )}
            </div>
        );
    };

    useEffect(() => {
        if (innerTableToggleObject?.isOpen) {
            rowExpandeEvent({
                rowIndex: innerTableToggleObject.rowIndex,
                isOpen: false,
            });

            setTimeout(() => {
                rowExpandeEvent({
                    rowIndex: innerTableToggleObject.rowIndex,
                    isOpen: true,
                });
            }, 1000);
        }
    }, [appliedTableSettings]);

    const innerTableProps = useMemo(() => {
        // provide Detail Cell Renderer Params
        const isSimilar =
            innerTableToggleObject?.toggleCellType == "similarMaterial";
        return {
            masterDetail: true,

            detailCellRendererParams: {
                detailGridOptions: {
                    suppressRowClickSelection: true,
                    suppressClickEdit: true,
                    suppressMovableColumns: true,
                    tooltipInteraction: true,
                    tooltipShowDelay: 500,
                    popupParent: document.body,
                    domLayout: "normal",
                    suppressHorizontalScroll: false,
                    // onBodyScroll: (event) => {
                    //     const { direction, left, top } = event;
                    //     console.log(
                    //         `Scrolling direction: ${direction}, ScrollLeft: ${left}, ScrollTop: ${top}`
                    //     );
                    // },
                    // onGridReady: (params) => {
                    //     const gridBody = params.api.gridBodyCtrl.eBodyViewport;
                    //     gridBody.addEventListener("scroll", (event) => {
                    //         const scrollLeft = gridBody.scrollLeft;
                    //         console.log(
                    //             `Horizontal Scroll Left: ${scrollLeft}`
                    //         );
                    //     });
                    // },
                    columnDefs: [
                        {
                            headerName:
                                attributeColumnDefMap[
                                    isSimilar
                                        ? "similarMaterial"
                                        : "pfsWasMaterial"
                                ].columnDef.headerName,
                            field: "material_number",
                            pinned: true,
                            cellRenderer: CellRendererForInnerTable,
                        },
                        {
                            field: "image_url",
                            headerName:
                                attributeColumnDefMap[
                                    isSimilar ? "smImage" : "pfsWasImage"
                                ].columnDef.headerName,
                            pinned: true,
                            lockPinned: true,
                            sortable: false,
                            suppressMovable: true,
                            filter: false,
                            cellStyle: {
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                            },
                            cellRenderer: (data) => (
                                <PriceListLevelImageCell data={data} />
                            ),
                            width: "80px",
                            tooltipComponent: ImageTooltipRenderer,
                            tooltipField: "image_url",
                        },
                        {
                            headerName:
                                attributeColumnDefMap[
                                    isSimilar ? "smSeason" : "pfsWasSeason"
                                ].columnDef.headerName,
                            field: "season_name",
                        },
                        {
                            headerName:
                                attributeColumnDefMap[
                                    isSimilar ? "smMarket" : "pfsWasMarket"
                                ].columnDef.headerName,
                            field: "market_name",
                        },
                        {
                            headerName:
                                attributeColumnDefMap.smChannel.columnDef
                                    .headerName,
                            field: "channel",
                            columnType: "similarMaterial",
                        },
                        {
                            headerName: "PFS WAS Mapping Type",
                            field: "pf_was_mapping_type",
                            columnType: "pfsWasMaterial",
                        },
                        {
                            headerName: isSimilar
                                ? attributeColumnDefMap.euStdMsrpMercOverride
                                      .columnDef.headerName
                                : "PFS WAS Price",
                            field: "msrp_price",
                            valueFormatter: euroFormatter,
                        },
                        {
                            headerName: isSimilar
                                ? attributeColumnDefMap.euStdWhslMercOverride
                                      .columnDef.headerName
                                : "Store Price",
                            field: "wholesale_price",
                            valueFormatter: euroFormatter,
                        },
                        {
                            headerName: isSimilar
                                ? attributeColumnDefMap.rtlPriceEffectiveDate
                                      .columnDef.headerName
                                : "PFS WAS Eff Date",
                            field: "msrp_effective_date",
                        },
                        {
                            headerName: isSimilar
                                ? attributeColumnDefMap.whslPriceEffectiveDate
                                      .columnDef.headerName
                                : "Store Eff Date",
                            field: "wholesale_effective_date",
                        },
                        {
                            headerName: "Merch Division",
                            field: "product_h1_name",
                        },
                        { headerName: "Season Code", field: "season_code" },
                        { headerName: "Season Name", field: "season_name" },
                        { headerName: "SAP Status", field: "sap_status" },
                        {
                            headerName: "IP Price Export Status",
                            field: "ip_price_export_status",
                        },
                        {
                            headerName: "GFE Pre Pack",
                            field: "gfe_pre_pack",
                        },
                        {
                            headerName: "GFE Material Status",
                            field: "gfe_material_status",
                        },
                        {
                            headerName: "Merch Org",
                            field: "product_h2_name",
                        },
                        { headerName: "Gender", field: "product_h3_name" },
                        { headerName: "Brand", field: "product_h5_name" },
                        {
                            headerName: "Merch Segment",
                            field: "product_h6_name",
                        },
                        {
                            headerName: "Merch Size Group",
                            field: "product_h7_name",
                        },
                        {
                            headerName: "Merch Category",
                            field: "product_h8_name",
                        },
                        {
                            headerName: "Product Category",
                            field: "product_h9_name",
                        },
                        {
                            headerName: "Product Class",
                            field: "product_h10_name",
                        },
                        {
                            headerName: "Product Subclass",
                            field: "product_h11_name",
                        },
                        // { headerName: "Product Level 1", field: "" },
                        // { headerName: "Product Level 2", field: "" },
                        // { headerName: "Product Level 3", field: "" },
                        // { headerName: "Product Level 4", field: "" },
                        {
                            headerName: "Brand Group",
                            field: "product_h4_name",
                        },
                        {
                            headerName: "Special Attribute",
                            field: "special_attribute",
                        },
                        {
                            headerName: "Board Number",
                            field: "board_number",
                        },
                        {
                            headerName: "GFE Pre-Pack",
                            field: "gfe_pre_pack",
                        },
                        { headerName: "Item Number", field: "item_number" },
                        {
                            headerName: "Lagecy Material Number",
                            field: "legacy_material_nbr",
                        },
                        {
                            headerName: "Style Number",
                            field: "style_number",
                        },
                        {
                            headerName: "Vendor Material Number",
                            field: "vendor_material_nbr",
                        },
                    ]
                        .map((item) => {
                            const obj = {
                                ...item,
                                cellStyle: ({ rowIndex, data }) => {
                                    if (rowIndex == 0)
                                        return {
                                            background: "#d3d3d3",
                                        };
                                    if (data.current_selection) {
                                        return { background: "#dde0ff" };
                                    }
                                    return {};
                                },
                            };
                            if (!("cellRenderer" in item)) {
                                obj.cellRenderer = innerTableFormatComponent;
                            }

                            return obj;
                        })
                        .filter((column) => {
                            const name = column.headerName;
                            let isFiltered = false;

                            if (
                                column?.columnType ==
                                innerTableToggleObject?.toggleCellType
                            ) {
                                isFiltered = true;
                            } else {
                                if (!column?.columnType) isFiltered = true;
                                else return false;
                            }

                            const foundColumn =
                                appliedTableSettings?.view_data?.columns.find(
                                    (item) => item.name == name
                                );

                            if (!foundColumn) isFiltered = true;
                            return foundColumn
                                ? foundColumn.isSelected
                                : isFiltered;
                        }),
                    // rowData: props.data.innterTable,
                    defaultColDef: {
                        minWidth: 200,
                        sortable: true,
                        filter: true,
                        valueFormatter: fallbackFormatter,
                    },
                },
                getDetailRowData: (params) => {
                    // console.log(params);
                    const {
                        data: { material_number },
                    } = params;

                    // return params.successCallback(
                    //     new Array(10).fill(dummyData)
                    // );

                    if (!innerTableToggleObject)
                        return params.successCallback([]);

                    if (
                        innerTableMemomisedData?.[material_number]?.[
                            innerTableToggleObject.toggleCellType
                        ]
                    ) {
                        return params.successCallback(
                            innerTableMemomisedData[material_number]?.[
                                innerTableToggleObject.toggleCellType
                            ]
                        );
                    }

                    rowExpandeEvent({
                        rowIndex: innerTableToggleObject.rowIndex,
                        isOpen: false,
                    });

                    const payload = {
                        region: selectedRegions[0].label,
                        channel: selectedChannels[0].label,
                        material_number: material_number,
                        market_id: selectedMarkets[0].value,
                        search_type: isSimilar ? "similar" : "pfs",
                        pfm_id: params?.node?.parent?.data?.id || "",
                    };

                    dispatch(getTopFiveMaterials(payload))
                        .then((res) => {
                            // console.log(res);
                            if (!innerTableMemomisedData[material_number]) {
                                innerTableMemomisedData[material_number] = {};
                            }
                            innerTableMemomisedData[material_number][
                                innerTableToggleObject.toggleCellType
                            ] = res;
                            params.successCallback(res);

                            setTimeout(() => {
                                rowExpandeEvent({
                                    rowIndex: innerTableToggleObject.rowIndex,
                                    isOpen: true,
                                });
                            }, 2000);
                        })
                        .catch(() => {
                            params.successCallback([]);
                        });
                },
            },
        };
    }, [innerTableToggleObject, appliedTableSettings]);

    return (
        <div style={{ position: "relative" }}>
            <LoadingOverlay
                showLoader={basePricingLoader !== 0}
                isLocalLoader
            />
            <div className="align-center justify-space-between">
                <div
                    className="align-center"
                    style={{
                        maxWidth: "calc(100% - 540px)",
                        marginRight: "12px",
                    }}
                >
                    {selectedPlanGroup ? (
                        <div
                            style={{
                                minWidth: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <span>Selected plan group : </span>
                            <Tooltip title={selectedPlanGroup.name}>
                                <strong>{selectedPlanGroup.name}</strong>
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
                <div
                    className="align-center flex-wrap"
                    style={{ gap: "20px", justifyContent: "flex-end" }}
                >
                    {materialEditMode ? (
                        <>
                            {permissions.edit ? (
                                <Tooltip title="Save edited material">
                                    <Button
                                        variant="contained"
                                        disabled={
                                            !permissions.edit ||
                                            !isEditMaterialDataValid ||
                                            basePricingAlertsConfigLoader !== 0
                                        }
                                        onClick={onMaterialSaveClick}
                                    >
                                        <SaveOutlinedIcon fontSize="small" />
                                    </Button>
                                </Tooltip>
                            ) : null}
                            <Button
                                variant="outlined"
                                onClick={onMaterialDiscardClick}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            {selectedMaterials.length ? (
                                <>
                                    <Button
                                        variant="contained"
                                        disabled={
                                            basePricingAlertsConfigLoader !== 0
                                        }
                                        onClick={onEditSpecialAttributeClick}
                                    >
                                        <EditOutlinedIcon
                                            fontSize="small"
                                            style={{
                                                marginRight: "4px",
                                            }}
                                        />
                                        Special Attribute
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={onGeneratePricelistClick}
                                        disabled={
                                            basePricingAlertsConfigLoader !== 0
                                        }
                                    >
                                        Generate Regional Pricing
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={onPriceRuleRefreshClick}
                                        disabled={
                                            basePricingAlertsConfigLoader !== 0
                                        }
                                    >
                                        Re-apply Price Rules
                                    </Button>
                                    {permissions.edit &&
                                    selectedMaterials.length > 1 ? (
                                        <Tooltip title="Bulk edit materials">
                                            <Button
                                                variant="contained"
                                                disabled={
                                                    selectedMaterials.length ===
                                                        1 ||
                                                    !permissions.edit ||
                                                    basePricingAlertsConfigLoader !==
                                                        0 ||
                                                    selectedMaterials.find(
                                                        (sr) =>
                                                            sr.data
                                                                .approval_status %
                                                                4 ===
                                                            2
                                                    )
                                                }
                                                onClick={
                                                    onMaterialBulkEditClick
                                                }
                                            >
                                                <EditNoteOutlinedIcon fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                    ) : null}
                                    {permissions.edit &&
                                    selectedMaterials.length === 1 ? (
                                        <Tooltip title="Edit selected material">
                                            <Button
                                                variant="contained"
                                                disabled={
                                                    selectedMaterials.length !==
                                                        1 ||
                                                    !permissions.edit ||
                                                    basePricingAlertsConfigLoader !==
                                                        0 ||
                                                    selectedMaterials[0].data
                                                        .approval_status %
                                                        4 ===
                                                        2
                                                }
                                                onClick={onMaterialEditClick}
                                            >
                                                <EditOutlinedIcon fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                    ) : null}
                                    {selectedChannels[0].label === "OUTLET" ? (
                                        <Tooltip title="Reset Options">
                                            <Button
                                                variant="contained"
                                                onClick={handleResetClick}
                                            >
                                                <AutorenewOutlinedIcon fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={onResetDatesClick}
                                            disabled={
                                                !permissions.edit ||
                                                basePricingAlertsConfigLoader !==
                                                    0
                                            }
                                        >
                                            Reset Effective dates
                                        </Button>
                                    )}
                                    {permissions.delete ? (
                                        <Tooltip title="Untag materials">
                                            <Button
                                                variant="contained"
                                                onClick={() =>
                                                    setShowUntagMaterialModal(
                                                        true
                                                    )
                                                }
                                                disabled={
                                                    !permissions.delete ||
                                                    basePricingAlertsConfigLoader !==
                                                        0
                                                }
                                            >
                                                <Untag
                                                    className="MuiSvgIcon-root"
                                                    style={{
                                                        width: "20px",
                                                        color: "white",
                                                    }}
                                                />
                                            </Button>
                                        </Tooltip>
                                    ) : null}
                                    <Modal
                                        open={showUntagMaterialModal}
                                        onClose={setShowUntagMaterialModal}
                                    >
                                        <div
                                            className={`${styles["untag-material-modal-container"]} flex-column overflow-hidden`}
                                        >
                                            <div>
                                                Are you sure you want to untag
                                                the selected materials?
                                            </div>
                                            <div
                                                className={`${styles["untag-material-modal-actions-container"]} button-group-flex`}
                                                style={{
                                                    justifyContent: "end",
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={
                                                        onMaterialUntagClick
                                                    }
                                                >
                                                    Untag
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() =>
                                                        setShowUntagMaterialModal(
                                                            false
                                                        )
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal>
                                </>
                            ) : (
                                <>
                                    {!isHistoricalData && (
                                        <div className={`align-center`}>
                                            <span
                                                className={`select-label no-wrap ${styles["input-label"]}`}
                                            >
                                                Exceptions
                                            </span>
                                            <div style={{ width: "180px" }}>
                                                <Select
                                                    className="input"
                                                    initialData={
                                                        exceptionOptions
                                                    }
                                                    selectedOptions={
                                                        selectedExceptionOptions
                                                    }
                                                    updateSelected={(data) => {
                                                        setSelectedExceptionOptions(
                                                            data.selectedItems
                                                        );
                                                    }}
                                                    isDisabled={
                                                        basePricingAlertsConfigLoader !==
                                                        0
                                                    }
                                                    is_multiple_selection
                                                    updateSelectedOnEachSelection
                                                />
                                            </div>
                                            {basePricingAlertsConfigLoader !==
                                            0 ? (
                                                <RotateRightIcon
                                                    className={`${styles["load-indicator"]}`}
                                                    titleAccess="Loading exceptions"
                                                />
                                            ) : null}
                                        </div>
                                    )}
                                    {!isHistoricalData && (
                                        <div className={`align-center`}>
                                            <span
                                                className={`select-label no-wrap ${styles["input-label"]}`}
                                            >
                                                Alerts
                                            </span>
                                            <div style={{ width: "180px" }}>
                                                <Select
                                                    className="input"
                                                    initialData={alertOptions}
                                                    selectedOptions={
                                                        selectedAlertOptions
                                                    }
                                                    updateSelected={(data) =>
                                                        setSelectedAlertOptions(
                                                            data.selectedItems
                                                        )
                                                    }
                                                    isDisabled={
                                                        basePricingAlertsConfigLoader !==
                                                        0
                                                    }
                                                    is_multiple_selection
                                                    updateSelectedOnEachSelection
                                                />
                                            </div>
                                            {basePricingAlertsConfigLoader !==
                                            0 ? (
                                                <RotateRightIcon
                                                    className={`${styles["load-indicator"]}`}
                                                    titleAccess="Loading alerts"
                                                />
                                            ) : null}
                                        </div>
                                    )}
                                    <Button
                                        variant="contained"
                                        onClick={onReuploadPriceFileClick}
                                        disabled={
                                            Object.values(
                                                countryPriceRequestOperations
                                            )?.find((e) => e.pending > 0) ||
                                            basePricingAlertsConfigLoader !==
                                                0 ||
                                            isHistoricalData
                                        }
                                    >
                                        Reupload Price File
                                    </Button>
                                    <Tooltip title="Refresh Base Pricing">
                                        <Button
                                            variant="contained"
                                            onClick={onRefreshClick}
                                        >
                                            <RefreshOutlinedIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                    <ClearAllFilterButton
                                        isVisible={isAnyFilterApplied}
                                        agGridApi={gridRef?.current?.api}
                                        onClick={() => {
                                            setSelectedExceptionOptions([]);
                                            setSelectedAlertOptions([]);
                                        }}
                                    />
                                    <Tooltip title="Table settings">
                                        <Button
                                            variant="contained"
                                            onClick={onSettingsClick}
                                        >
                                            <SettingsIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                        </>
                    )}
                    <StyledMenu
                        id="regional-pricing-download-menu"
                        anchorEl={anchorEl}
                        open={downloadMenuOpen}
                        onClose={handleClose}
                        sx={{ margin: 0 }}
                    >
                        <MenuItem onClick={onDownloadTableDataClick}>
                            Table Data
                        </MenuItem>
                        <MenuItem
                            onClick={onDownloadAuditLogClick}
                            disabled={isHistoricalData}
                        >
                            Audit Log
                        </MenuItem>
                    </StyledMenu>
                    <StyledMenu
                        id="regional-pricing-reset"
                        anchorEl={resetAnchorEl}
                        open={resetMenuOpen}
                        onClose={() => setResetAnchorEl(null)}
                        sx={{ margin: 0 }}
                    >
                        <MenuItem
                            onClick={onResetDatesClick}
                            disabled={
                                !permissions.edit ||
                                basePricingAlertsConfigLoader !== 0
                            }
                        >
                            Reset Effective dates
                        </MenuItem>
                        <MenuItem
                            onClick={() => onResetMaterials("similar")}
                            disabled={
                                !permissions.edit ||
                                basePricingAlertsConfigLoader !== 0
                            }
                        >
                            Reset Similar Materials
                        </MenuItem>
                        <MenuItem
                            onClick={() => onResetMaterials("pfs")}
                            disabled={
                                !permissions.edit ||
                                basePricingAlertsConfigLoader !== 0
                            }
                        >
                            Reset PFS Was
                        </MenuItem>
                    </StyledMenu>
                    {selectedChannels[0].label === "OUTLET" &&
                        !selectedMaterials?.length && (
                            <Tooltip title="Upload Discount">
                                <Button
                                    variant="contained"
                                    onClick={onDiscountUploadClick}
                                >
                                    <FileUploadOutlinedIcon />
                                </Button>
                            </Tooltip>
                        )}

                    {permissions.download ? (
                        <Tooltip title="Download materials">
                            <Button
                                variant="contained"
                                onClick={handleClick}
                                disabled={
                                    !permissions.download ||
                                    materials?.length === 0
                                }
                            >
                                <DownloadOutlinedIcon fontSize="small" />
                            </Button>
                        </Tooltip>
                    ) : null}
                </div>
            </div>
            <div
                style={{ height: "calc(100vh - 340px)", marginTop: "15px" }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    rowData={materials}
                    rowHeight={
                        appliedTableSettings?.view_data.columns
                            .filter((col) => col.isSelected)
                            .map((sta) => sta.id)
                            .includes("productImage")
                            ? "55"
                            : "45"
                    }
                    columnDefs={colDefs}
                    rowSelection="multiple"
                    editType="fullRow"
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        menuTabs: ["filterMenuTab", "generalMenuTab"],
                        showDisabledCheckboxes: !materialEditMode,
                        valueFormatter: fallbackFormatter,
                        cellStyle: {
                            display: "flex",
                            alignItems: "center",
                        },
                        filter: "agMultiColumnFilter",
                        floatingFilter: true,
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                        },
                        valueGetter: (params) =>
                            params.data[params.colDef.field] ?? "-",
                    }}
                    suppressRowClickSelection
                    suppressColumnVirtualisation
                    suppressClickEdit
                    suppressMovableColumns
                    onSelectionChanged={onMaterialSelect}
                    onFilterChanged={onFilterChanged}
                    onModelUpdated={onTableDataChange}
                    enableRangeSelection={true}
                    excelStyles={excelStyles}
                    // onCellValueChanged={onCellValueChange}
                    // onRowValueChanged={onRowValueChange}
                    isRowSelectable={isRowSelectable}
                    processCellForClipboard={processCellForClipboard}
                    onColumnResized={onColumnResize}
                    tooltipInteraction={true}
                    tooltipShowDelay={500}
                    popupParent={document.body}
                    {...innerTableProps}
                />
            </div>
            <TableRowCount count={rowCount} selected={selectedRowCount} />
            {isAuditLogModalOpen ? (
                <AuditLogModal
                    isAuditLogModalOpen={isAuditLogModalOpen}
                    setIsAuditLogModalOpen={setIsAuditLogModalOpen}
                    auditLogSrc={auditLogSrc}
                />
            ) : null}
            <Modal
                open={confirmDateChangeModalOpen}
                onClose={onConfirmDateChangeModalClose}
            >
                <div
                    className={`${styles["reset-modal-container"]} flex-column overflow-hidden`}
                >
                    <div className="flex-column align-center">
                        <strong style={{ marginBottom: "23px" }}>
                            Changing Price Effective Dates
                            {dateChangeMode === "reset" ? "" : " and/or Price"}?
                        </strong>
                        <div className={`${styles["sub-title"]}`}>
                            Are you sure you want to{" "}
                            {dateChangeMode === "reset" ? "reset" : "change"}{" "}
                            Effective Dates
                            {dateChangeMode === "reset" ? "" : " and/or Price"}?
                        </div>
                        <div
                            className={`${styles["warning-container"]}`}
                            style={{ display: "flex" }}
                        >
                            <div className={`${styles["icon"]} justify-center`}>
                                i
                            </div>
                            The dates
                            {dateChangeMode === "reset"
                                ? ""
                                : " and/or price"}{" "}
                            will be overridden with the updated values
                        </div>
                    </div>
                    <div
                        className={`${styles["reset-modal-actions-container"]} button-group-flex`}
                        style={{ justifyContent: "end", marginTop: "16px" }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => onConfirmDateChangeSubmit()}
                        >
                            Change
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => onConfirmDateChangeCancel()}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={specialAttributeModalOpen}
                onClose={onEditSpecialAttributeClose}
            >
                <div
                    className={`${styles["special-attribute-modal-container"]} ${styles["sm"]} flex-column`}
                >
                    <div className="center-space-between mb-12">
                        <div className={`${styles["page-sub-title"]}`}>
                            Edit Special Attribute
                        </div>
                        <button
                            className={`${styles["close-btn"]} pointer`}
                            onClick={onEditSpecialAttributeClose}
                        >
                            &times;
                        </button>
                    </div>
                    <label
                        className="field-title"
                        htmlFor="specialAttributeName"
                    >
                        Special Attribute
                    </label>
                    <TextField
                        id="specialAttributeName"
                        className="input"
                        placeholder="Type special attribute..."
                        autoFocus
                        error={newSpecialAttribute.trim().length > 255}
                        helperText={
                            newSpecialAttribute.trim().length > 255
                                ? "Max 255 characters"
                                : null
                        }
                        value={newSpecialAttribute}
                        onChange={(e) => setNewSpecialAttribute(e.target.value)}
                    />
                    <div
                        className={`${styles["modal-actions-container"]} button-group-flex`}
                    >
                        <Button
                            variant="contained"
                            onClick={onEditSpecialAttributeSubmit}
                            disabled={
                                !newSpecialAttribute.trim().length ||
                                newSpecialAttribute.trim().length > 255
                            }
                        >
                            Submit
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={onEditSpecialAttributeClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal open={bulkEditModalOpen} onClose={onBulkEditModalClose}>
                <div
                    className={`${styles["bulk-edit-modal-container"]} flex-column overflow-hidden`}
                >
                    <div className={styles["bulk-edit-modal-title"]}>
                        <span>Bulk Edit</span>
                        <button
                            className={styles["bulk-edit-modal-close-btn"]}
                            onClick={onBulkEditModalCancel}
                        >
                            &times;
                        </button>
                    </div>
                    <div className={styles["bulk-edit-date-container"]}>
                        <div className="flex-column">
                            <strong style={{ fontSize: "18px" }}>
                                {selectedChannels[0].label === "FULL-PRICE"
                                    ? "Euro Standard Price"
                                    : "PFS Was (Eur)"}
                                <Tooltip title="The changes are applied on all the materials selected">
                                    <InfoOutlinedIcon
                                        className={styles["info-bulk-edit"]}
                                        fontSize="small"
                                    />
                                </Tooltip>
                                <Switch
                                    checked={isPriceChangeEnabled}
                                    onChange={() =>
                                        setIsPriceChangeEnabled(
                                            !isPriceChangeEnabled
                                        )
                                    }
                                />
                            </strong>
                        </div>
                        <div
                            className={`${styles["date-section"]}`}
                            style={{ marginBottom: "12px", marginTop: "8px" }}
                        >
                            <div style={{ width: "200px" }}>
                                <TextField
                                    sx={{
                                        width: "100px",
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                display: "none",
                                            },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                        "& .MuiInputBase-root": {
                                            paddingRight: "3px",
                                        },
                                    }}
                                    value={priceChangeValue}
                                    type="number"
                                    min={0}
                                    onChange={(e) => {
                                        const tempFunc =
                                            e.target.value > 0
                                                ? parseFloat(
                                                      parseFloat(
                                                          e.target.value
                                                      ).toFixed(2)
                                                  )
                                                : 0;
                                        const val = isNaN(
                                            parseFloat(e.target.value)
                                        )
                                            ? e.target.value
                                            : tempFunc;
                                        setPriceChangeValue(val);
                                    }}
                                    disabled={!isPriceChangeEnabled}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            "#efefef",
                                                        borderRadius: "4px",
                                                        padding: "5px 8px",
                                                    }}
                                                >
                                                    €
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {selectedChannels?.[0]?.label === "OUTLET" && (
                        <div className={styles["bulk-edit-date-container"]}>
                            <div className="flex-column">
                                <strong style={{ fontSize: "18px" }}>
                                    Discount
                                    <Tooltip title="The changes are applied on all the materials selected">
                                        <InfoOutlinedIcon
                                            className={styles["info-bulk-edit"]}
                                            fontSize="small"
                                        />
                                    </Tooltip>
                                    <Switch
                                        checked={isDiscountChangeEnabled}
                                        onChange={() =>
                                            setIsDiscountChangeEnabled(
                                                !isDiscountChangeEnabled
                                            )
                                        }
                                    />
                                </strong>
                            </div>
                            <div
                                className={`${styles["date-section"]}`}
                                style={{
                                    marginBottom: "12px",
                                    marginTop: "8px",
                                }}
                            >
                                <div style={{ width: "200px" }}>
                                    <TextField
                                        sx={{
                                            width: "100px",
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                {
                                                    display: "none",
                                                },
                                            "& input[type=number]": {
                                                MozAppearance: "textfield",
                                            },
                                            "& .MuiInputBase-root": {
                                                paddingRight: "3px",
                                            },
                                        }}
                                        value={discountChangeValue}
                                        type="number"
                                        min={0}
                                        onChange={(e) => {
                                            setDiscountChangeValue(
                                                e.target.value
                                            );
                                        }}
                                        disabled={!isDiscountChangeEnabled}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                "#efefef",
                                                            borderRadius: "4px",
                                                            padding: "5px 8px",
                                                        }}
                                                    >
                                                        %
                                                    </span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles["bulk-edit-date-container"]}>
                        <div className="flex-column">
                            <strong style={{ fontSize: "18px" }}>
                                Effective Dates
                                <Tooltip title="The changes are applied on all the materials selected">
                                    <InfoOutlinedIcon
                                        className={styles["info-bulk-edit"]}
                                        fontSize="small"
                                    />
                                </Tooltip>
                                <Switch
                                    checked={isDateChangeEnabled}
                                    onChange={() =>
                                        setIsDateChangeEnabled(
                                            !isDateChangeEnabled
                                        )
                                    }
                                />
                            </strong>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div className={`${styles["date-section"]}`}>
                                <strong className={`${styles["title"]}`}>
                                    MSRP Price Effective Date
                                </strong>
                                <div className={`${styles["date-picker"]}`}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                    >
                                        <DatePicker
                                            value={rtlDateChangeValue}
                                            closeOnSelect
                                            format="MM/DD/YYYY"
                                            disabled={
                                                rtlDateChangeMode !== "edit" ||
                                                !isDateChangeEnabled
                                            }
                                            onChange={onRtlDateChange}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <RadioGroup
                                    row
                                    value={rtlDateChangeMode}
                                    onChange={onRtlDateChangeMode}
                                    className={styles["radio-group"]}
                                >
                                    <FormControlLabel
                                        value="reset"
                                        control={<Radio size="small" />}
                                        label="Reset Dates"
                                        disabled={!isDateChangeEnabled}
                                    />
                                    <FormControlLabel
                                        value="edit"
                                        control={<Radio size="small" />}
                                        label="Edit Manually"
                                        disabled={!isDateChangeEnabled}
                                    />
                                </RadioGroup>
                            </div>
                            <div className={`${styles["date-section"]}`}>
                                <strong className={`${styles["title"]}`}>
                                    WHSL Price Effective Date
                                </strong>
                                <div className={`${styles["date-picker"]}`}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                    >
                                        <DatePicker
                                            value={whslDateChangeValue}
                                            closeOnSelect
                                            format="MM/DD/YYYY"
                                            disabled={
                                                whslDateChangeMode !== "edit" ||
                                                !isDateChangeEnabled
                                            }
                                            onChange={onWhslDateChange}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <RadioGroup
                                    row
                                    value={whslDateChangeMode}
                                    onChange={onWhslDateChangeMode}
                                    className={styles["radio-group"]}
                                    disabled={!isDateChangeEnabled}
                                >
                                    <FormControlLabel
                                        value="reset"
                                        control={<Radio />}
                                        label="Reset Dates"
                                        disabled={!isDateChangeEnabled}
                                    />
                                    <FormControlLabel
                                        value="edit"
                                        control={<Radio />}
                                        label="Edit Manually"
                                        disabled={!isDateChangeEnabled}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${styles["reset-modal-actions-container"]} button-group-flex`}
                        style={{ justifyContent: "end" }}
                    >
                        <Button
                            variant="text"
                            onClick={() => onBulkEditModalCancel()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            disabled={
                                !isPriceChangeEnabled &&
                                !isDiscountChangeEnabled &&
                                !isDateChangeEnabled
                            }
                            onClick={() => onBulkEditSubmit()}
                        >
                            Update Values
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={confirmGenerateCountryLevelPriceModalOpen}
                onClose={onConfirmGenerateCountryLevelPriceModalClose}
            >
                <div
                    className={`${styles["reset-modal-container"]} flex-column overflow-hidden`}
                >
                    <div className="flex-column align-center">
                        <strong>
                            {confirmGenerateCountryLevelPriceModalText}
                        </strong>
                    </div>
                    <div
                        className={`${styles["reset-modal-actions-container"]} button-group-flex`}
                        style={{ justifyContent: "end" }}
                    >
                        <Button
                            variant="contained"
                            onClick={() =>
                                onConfirmGenerateCountryLevelPriceProceed(
                                    materialsForPriceGeneration
                                )
                            }
                        >
                            Proceed
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={onConfirmGenerateCountryLevelPriceCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            {showTableSettings ? (
                <>
                    <div className="drawer-inner-full-height sm">
                        <TableSettings
                            basePriceSettings
                            onApply={onTableSettingsApply}
                            onClose={onTableSettingsClose}
                            selectedChannels={selectedChannels}
                        />
                    </div>
                    <div className="drawer-inner-full-height-cover" />
                </>
            ) : null}
            <DiscountUploadModal
                showModal={showDiscountModel}
                setShowModel={setShowDiscountModal}
                selectedMaterials={selectedMaterials}
                selectedMarkets={selectedMarkets}
                selectedRegions={selectedRegions}
                selectedChannels={selectedChannels}
                refreshTable={onRefreshClick}
            />
        </div>
    );
}

EUPriceListLevelPrices.propTypes = {
    page: PropTypes.string,
    filterPage: PropTypes.string,
    onReuploadPriceFileClick: PropTypes.func,
    currentTab: PropTypes.string,
};

export default EUPriceListLevelPrices;
